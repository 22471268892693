import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormikController from "../../Forms/FormikController";
import { HTTP } from "../../../HTTPS";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Button } from "antd";
import { useAuth } from "../../auth";
import TextError from "../../Forms/TextError";

const UpdateProduct = ({ initialValues, handleClose, lists, getBpStore }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const validationSchema = Yup.object({
    quality: Yup.string().required("Required"),
    line: Yup.string().required("Required"),
    packetQnt: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    packetWeight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
  });
  const quality = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];
  const lines = [
    { key: "101A", value: "101A" },
    { key: "76B", value: "76B" },
    { key: "76C", value: "76C" },
    { key: "51D", value: "51D" },
  ];
  const types = [
    { key: "hr", value: "HR" },
    { key: "gi", value: "GI" },
    { key: "gr", value: "CR" },
  ];

  const handleUpdate = (values, onSubmitProps) => {
    Swal.fire({
      title: `${t("on_update")}`,
      showCancelButton: true,
      confirmButtonText: `${t("update")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      onSubmitProps.setSubmitting(true);
      if (result.isConfirmed) {
        await HTTP.patch(
          `/produce/update/${values.id}?_updatedBy=${localStorage.getItem(
            "username"
          )}`,
          values
        )
          .then((response) => {
            getBpStore();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("updated")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            handleClose();
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
      onSubmitProps.setSubmitting(false);
    });
  };
  return (
    <Formik
      initialValues={initialValues.production}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
      validateOnMount
    >
      {(formik) => (
        <>
          <Row className="mb-3 addUserForm">
            <Form className="w-100 mb-3">
              <Row>
                <Row className="mb-4">
                  <Col md={8}>
                    <Autocomplete
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      size="small"
                      id="combo-box-demo"
                      name="list.name"
                      label={t("list_name")}
                      options={lists}
                      getOptionLabel={(option) =>
                        `${option.name}*${option.type}`
                      }
                      value={formik.values.list}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                        } else {
                          formik.setFieldValue(
                            "productCode",
                            formik.values.productCode.replace(
                              formik.values.list.type,
                              newValue.type
                            )
                          );
                          formik.setFieldValue("list", newValue);
                          formik.setFieldValue("listId", newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<em>{t("select_list")}</em>}
                        />
                      )}
                    />
                  </Col>
                  <Col md={4}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.list.type}
                      options={types}
                      name="list.type"
                      label={t("type")}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={5}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.quality}
                      error={formik.errors?.quality}
                      touched={formik.touched?.quality}
                      options={quality}
                      name="quality"
                      label={t("quality")}
                    />
                  </Col>
                  <Col md={5}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-select-small">
                        {t("line")}
                      </InputLabel>
                      <Select
                        size="small"
                        style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                        labelId="demo-select-small"
                        id="demo-select-small"
                        className="input-bg"
                        value={formik.values.line}
                        label={t("line")}
                        error={
                          formik.touched?.line && Boolean(formik.errors?.line)
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "productCode",
                            formik.values.productCode.replace(
                              formik.values.line,
                              e.target.value
                            )
                          );
                          formik.setFieldValue("line", e.target.value);
                        }}
                        onBlur={() => formik.setFieldTouched("line", true)}
                      >
                        {lines.map((option) => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorMessage name={"line"} component={TextError} />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={5}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.packetQnt}
                      error={formik.errors.packetQnt}
                      touched={formik.touched.packetQnt}
                      type="number"
                      name="packetQnt"
                      label={t("packet_qnt")}
                      max={formik.values.list.standartPacketQnt}
                    />
                  </Col>
                  <Col md={5}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.packetWeight}
                      error={formik.errors.packetWeight}
                      touched={formik.touched.packetWeight}
                      type="number"
                      name="packetWeight"
                      label={t("packet_weight")}
                      step={0.01}
                    />
                  </Col>
                </Row>
                <div className="w-100 ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("update")}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </>
      )}
    </Formik>
  );
};

export default UpdateProduct;

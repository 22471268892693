import Cookies from "js-cookie";
import React, { useRef } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "../../assets/imgs/logo.png";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";
import { AiFillPrinter } from "react-icons/ai";

import "../../assets/style.css";
import moment from "moment/moment";

const Receipt = ({ initialValues }) => {
  const componentRef = useRef();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@media print {
        @page {
          size: lanscape;
        }
      }`,
  });
  return (
    <div>
      <div className="mt-2">
        <Button
          variant="outlined"
          onClick={() => {
            handlePrint();
          }}
        >
          <span className={currentLanguageCode == "en" ? "en-f" : "kr-f"}>
            <AiFillPrinter /> {t("print")}
          </span>
        </Button>
      </div>
      <div className="receipt mt-3">
        <div
          ref={componentRef}
          style={{
            width: "100%",
            height: "100%",
          }}
          className={currentLanguageCode == "en" ? "ltr" : "rtl"}
        >
          <Container className="p-0">
            <hr className="receipt-header-hr m-0 mb-3" />
            <Row className="p-0">
              <Col
                xs={{ order: currentLanguageCode == "en" ? 1 : 3 }}
                className={`d-flex align-items-center justify-content-${
                  currentLanguageCode == "en" ? "start" : "end"
                }`}
              >
                <div>
                  <div className="eng text-center mb-3">
                    Black <span className="text-danger">Pipe</span> Factory for
                    Manufacturing Iron Pipes
                  </div>
                  <div className="ar text-center">
                    معمل بلاك <span className="text-danger">بايب</span> لأنتاج
                    انابيب الحديدية
                  </div>
                </div>
              </Col>
              <Col
                xs={{ order: 2 }}
                className={`d-flex align-items-center justify-content-${
                  currentLanguageCode == "en" ? "end" : "start"
                }`}
              >
                <img src={logo} className="img-fluid" alt="" />
              </Col>
              <Col
                xs={{ order: currentLanguageCode == "en" ? 3 : 1 }}
                className={`d-flex align-items-center justify-content-${
                  currentLanguageCode == "en" ? "end" : "start"
                }`}
              >
                <div className="krd text-center">
                  کارگەی بلاك <span className="text-danger">پایپ</span> بۆ
                  بەرهەمهێنانی پرۆفیل
                </div>
              </Col>
            </Row>
            <hr className="receipt-hr m-0 my-2" />
            <Row className="text-center my-4">
              <Col xs={{ span: 3, order: currentLanguageCode == "en" ? 1 : 3 }}>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    اسم السائق
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#F1F1F1",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#F1F1F1",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    رقم السيارة
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    الموبايل
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 6, order: 2 }} className="px-5">
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#FEFF1E",
                      width: "100%",
                      marginBottom: "1px",
                    }}
                  >
                    وصل استلام المواد
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#808080",
                      width: "35%",
                      marginBottom: "1px",
                      color: "white",
                    }}
                  >
                    المنفذ
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#808080",
                      width: "35%",
                      marginBottom: "1px",
                      color: "white",
                    }}
                  >
                    اسم الشريكة
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 3, order: currentLanguageCode == "en" ? 3 : 1 }}>
                <div className="d-flex w-100">
                  <div
                    className="bgCG1"
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    رقم الطلبیة
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#F1F1F1",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#F1F1F1",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    تسلسل
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "65%",
                      marginBottom: "1px",
                      marginInlineEnd: "1px",
                    }}
                  >
                    <input
                      type="text"
                      defaultValue={moment().format("Y-MM-DD")}
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#D9D9D9",
                      width: "35%",
                      marginBottom: "1px",
                    }}
                  >
                    التاريخ
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              responsive
              bordered
              striped
              className="text-center mt-4"
              dir="rtl"
            >
              <thead>
                <tr className="receipt-table-header">
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "5%" }}
                  >
                    ت
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "15%" }}
                  >
                    اسم المادة
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "15%" }}
                  >
                    الكود
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "10%" }}
                  >
                    الوزن الصافي
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "8%" }}
                  >
                    الوزن الكلي
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "8%" }}
                  >
                    النوعية
                  </td>
                  <td
                    className="bg-danger text-white p-0"
                    style={{ width: "25%" }}
                  >
                    الملاحضة
                  </td>
                </tr>
              </thead>
              <tbody className="receipt-table-body">
                {initialValues.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-0">{index + 1}</td>
                      <td className="p-0">
                        {val.type}
                        {val.thikness}
                      </td>
                      <td className="p-0">{val.code}</td>
                      <td className="p-0">{val.netWeight}</td>
                      <td className="p-0">{val.grossWeight}</td>
                      <td className="p-0">{val.madeIn}</td>
                      <td className="p-0">
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            paddingInline: "15px",
                            textAlign: "center",
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr style={{ border: "none" }}>
                  <td className="p-0" style={{ border: "none" }}></td>
                  <td className="p-0" style={{ border: "none" }}></td>
                  <td
                    className="p-0"
                    style={{
                      border: "none",
                      backgroundColor: "#193863",
                      color: "white",
                    }}
                  >
                    الاجمالي
                  </td>
                  <td
                    className="p-0"
                    style={{ border: "none", backgroundColor: "#F2BD12" }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </td>
                  <td
                    className="p-0"
                    style={{ border: "none", backgroundColor: "#F2BD12" }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </td>
                  <td
                    className="p-0"
                    style={{
                      border: "none",
                      backgroundColor: "#193863",
                      color: "white",
                    }}
                  >
                    وزن المعمل
                  </td>
                  <td
                    className="p-0"
                    style={{ border: "none", backgroundColor: "#F2BD12" }}
                  >
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        paddingInline: "15px",
                        textAlign: "center",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Receipt;

import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { HTTP } from "../../../HTTPS";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, DatePicker, Input, Select, Space, Spin, Table } from "antd";
import moment from "moment/moment";
import { AiOutlineRollback } from "react-icons/ai";
const { Search } = Input;

const Transfers = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [transfers, setTransfers] = useState([]);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [sold, setSold] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const transfersColumns = [
    {
      title: "#",
      width: 15,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("code")}`,
      key: "productCode",
      width: 75,
      render: (record) => <>{record.production?.productCode}</>,
    },
    {
      title: `${t("list_name")}`,
      key: "list_name",
      width: 75,
      render: (record) => (
        <>
          {record.production?.list?.name}*{record.production?.list?.type}
        </>
      ),
    },
    {
      title: `${t("transferred_to")}`,
      key: "to",
      width: 75,
      render: (record) => <>{record.to}</>,
    },
    {
      title: `${t("quantity")}`,
      key: "quantity",
      width: 75,
      render: (record) => (
        <>{record.qnt ? record.qnt : record.production?.packetQnt}</>
      ),
    },
    {
      title: `${t("weight")}`,
      key: "weight",
      width: 75,
      render: (record) => (
        <>{record.weight ? record.weight : record.production?.packetWeight}</>
      ),
    },
    {
      title: `${t("date")}`,
      key: "date",
      width: 75,
      render: (record) => (
        <span>{moment(record.createdAt).format("Y.MM.DD HH:mm")}</span>
      ),
    },
    ...(sold
      ? [
          {
            dataIndex: "",
            width: 100,
            fixed: "right",
            key: "id",
            render: (record) => (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    handleReturn(record);
                  }}
                  type="primary"
                  ghost
                >
                  <AiOutlineRollback />{" "}
                  <span className="d-none d-md-inline"> {t("return")}</span>
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];

  const handleReturn = (record) => {
    var data = {
      to: "Factory",
      productionId: record.production.id,
      transferId: record.id,
      returnedBy: localStorage.getItem("username"),
    };
    Swal.fire({
      title: `${t("on_return")}`,
      showCancelButton: true,
      confirmButtonText: `${t("return")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HTTP.post(`/transfers/return`, data)
          .then((response) => {
            getData();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("returned")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    });
  };
  const getData = async () => {
    setLoading(true);
    await HTTP.get(
      `/transfers/all?_sold=${sold}&_from=Factory&_limit=${limit}&_page=${currentPage}&_search=${search}&_date=${date}`
    )
      .then((response) => {
        setLoading(false);
        setCount(Math.ceil(response.data.count / limit));
        setTransfers(response.data.rows);
      })
      .catch((error) => {
        setLoading(false);
        setTransfers([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getData();
  }, [limit, currentPage, search, date, sold]);
  return (
    <>
      <Row className="mt-5">
        <Col md={4}>
          <h4 className="mb-3">{t("transfers")} </h4>
        </Col>
        <Col md={2}>
          <Select
            className="w-100 my-1 my-md-2"
            defaultValue={t("transferred")}
            onChange={(e) => {
              setSold(e);
            }}
            options={[
              { value: 0, label: t("transferred") },
              { value: 1, label: t("sold") },
            ]}
          />
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={transfersColumns}
        defaultExpandAllRows={true}
        size="small"
        className="tbs bg"
        id="rollsTable"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        pagination={{
          onChange: (current, size) => {
            setLimit(size);
            setCurrentPage(current);
          },
          total: Math.floor(count * limit),
          defaultPageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <div className="px-md-4">
                <span className=" lh-base">{t("driverName")}: </span>
                <span className="fw-bold">{record.driverName}</span>
                <br />
                <span className=" lh-base">{t("driverPhone")}: </span>
                <span className="fw-bold">{record.driverPhone}</span>
                <br />
                <span className=" lh-base">{t("carNo")}: </span>
                <span className="fw-bold">{record.carNo}</span>
                <br />
                <span className=" lh-base">{t("carType")}: </span>
                <span className="fw-bold">{record.carType}</span>
                <br />
                <span className=" lh-base">{t("transferred_by")}: </span>
                <span className="fw-bold">{record.sentBy}</span>
                <br />
              </div>
            </>
          ),
          columnWidth: 12,
        }}
        dataSource={transfers}
      />
    </>
  );
};

export default Transfers;

import { Button, Space, Spin, Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HTTP } from "../../../HTTPS";
import moment from "moment/moment";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import { AiTwotoneEdit } from "react-icons/ai";
import { Container, Modal } from "react-bootstrap";
import UpdateProduct from "./UpdateProduct";

const EachProuct = ({
  record,
  getQStore,
  handleAddSelectedRows,
  handleRemoveSelectedRows,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [eachData, setEachData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState([]);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const handleUpdateModalClose = () => {
    setUpdateRecord([]);
    setOpenUpdateModal(false);
  };
  const handleUpdateRecord = (record) => {
    setUpdateRecord(record);
    setOpenUpdateModal(true);
  };

  const productionsColumns = [
    {
      title: `${t("qnt")}`,
      key: "packetQnt",
      width: 35,
      render: (record) => (
        <>
          <div
            className={`${
              Number(record.production.packetQnt) <
              Number(record.production.list.standartPacketQnt)
                ? "bg_tarqeq"
                : ""
            }`}
          >
            {record.production.packetQnt} {t("pipes")}
          </div>
        </>
      ),
    },
    {
      title: `${t("weight")}`,
      key: "packetWeight",
      width: 35,
      render: (record) => (
        <>
          {record.production.packetWeight} {t("kg")}
        </>
      ),
    },
    {
      title: `${t("code")}`,
      key: "productCode",
      width: 50,
      render: (record) => <>{record.production.productCode}</>,
    },
    {
      title: `${t("from")}`,
      key: "from",
      width: 50,
      render: (record) => <>{record.production.from}</>,
    },
    {
      title: `${t("date")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>{moment(record.createdAt).format("Y.MM.DD HH:mm")}</span>
      ),
    },
    ...(role == "Admin" || role == "Accountant" || role == "QandelBlbas"
      ? [
          {
            dataIndex: "",
            width: 40,
            fixed: "right",
            key: "id",
            render: (record) => (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    // getQStore();
                    handleUpdateRecord(record);
                  }}
                  type="primary"
                  ghost
                >
                  <AiTwotoneEdit />{" "}
                  <span className="d-none d-md-inline"> {t("edit")}</span>
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];

  const getLists = async () => {
    await HTTP.get(`/lists/all?_search=&_from=Factor`)
      .then((response) => {
        setLists(response.data.rows);
      })
      .catch((error) => {
        setLists([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const getEach = async () => {
    setLoading(true);
    await HTTP.get(
      `/qbstore/each?_name=${record.production.list.name}&_type=${record.production.list.type}&_line=${record.production.line}&_quality=${record.production.quality}`
    )
      .then((response) => {
        setLoading(false);
        setEachData(response.data.rows);
      })
      .catch((error) => {
        setLoading(false);
        setEachData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const rowSelection = {
    onSelect: (record, selected) => {
      if (selected) {
        handleAddSelectedRows(record.id);
      } else if (!selected) {
        handleRemoveSelectedRows(record.id);
      }
    },
  };
  useEffect(() => {
    getEach();
    getLists();
  }, []);
  return (
    <div className="px-5">
      <Table
        columns={productionsColumns}
        size="small"
        className="tbss  my-3"
        id="rollsTable"
        rowKey="id"
        pagination={false}
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          ...rowSelection,
          getCheckboxProps: () => ({
            className: `${
              role == "Admin" || role == "Accountant" || role == "QandelBlbas"
                ? ""
                : "d-none"
            }`,
          }),
        }}
        dataSource={eachData}
      />
      {/* UPDATE MODAL */}
      <Modal
        show={openUpdateModal}
        onHide={handleUpdateModalClose}
        size="lg"
        aria-labelledby="update-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="update-modal-title">{t("update")}</Modal.Title>
        </Modal.Header>
        <Container className="p-5">
          <UpdateProduct
            initialValues={updateRecord}
            lists={lists}
            handleClose={handleUpdateModalClose}
            getQStore={getQStore}
          />
        </Container>
      </Modal>
    </div>
  );
};

export default EachProuct;

import React from "react";
import { HTTP } from "../../../HTTPS";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Space, Spin, Table } from "antd";

const EachPlet = ({ record }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [pletData, setPletData] = useState([]);
  const [loading, setLoading] = useState(true);

  const eachColumns = [
    {
      title: `${t("count")}`,
      key: "id",
      width: 25,
      render: (record) => (
        <span>
          {record.count}
        </span>
      ),
    },
    {
      title: `${t("length")}`,
      width: 25,
      key: "index",
      render: (record) => (
        <span>
          {record.len} {t("m")}
        </span>
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    await HTTP.get(`/plets/each/${record.id}`)
      .then((response) => {
        setLoading(false);
        setPletData(response.data.rows[0].plets);
      })
      .catch((error) => {
        setLoading(false);
        setPletData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Table
      columns={eachColumns}
      size="small"
      className="m-md-3 mb-4"
      id="rollsTable"
      tableLayout="fixed"
      rowKey="id"
      loading={{
        indicator: (
          <Space size="middle">
            <Spin size="large" />
          </Space>
        ),
        spinning: loading,
      }}
      pagination={false}
      dataSource={pletData}
    />
  );
};

export default EachPlet;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    blackpipe: "Black Pipe",
    dashboard: "Dashboard",
    users: "Users",
    login: "Login",
    logout: "Log out",
    roll: "Rolls",
    store: "Store",
    rolls: "Rolls",
    lists: "Lists",
    activity: "Activity",
    slitter: "Slitter",
    pieces: "Pieces",
    piece: "Pieces",
    wastes: "Wastes",
    tarqeq: "Thinning",
    tambur: "Tambur",
    sent_to_tambur: "Sent to Tambur",
    list_name: "List Name",
    produce: "Produce",
    produce_new: "Produce New Product",
    f_store: "Factor Store",
    bp_store: "Black Pipe Store",
    qb_store: "Qandel Blbas Store",
    transfers: "Transfers",
    slitteds: "Slitted Rolls",
    deleted_slitteds: "Deleted Slitted Rolls",
    deleted_pieces: "Deleted Pieces",
    wastings: "Wastings ",
    removed_from_tambur: "Removed from Tambur",
    spare_lists: "Spare Lists",
    spare_parts: "Spare Parts",
    deleted_rolls: "Deleted Rolls",
    cut_roll: "Cut Roll",
    plets: "Plets",
    sold_plets: "Sold Plets",
    //
    kg: "kg",
    m: "m",
    mm: "mm",
    is_cut:"Is Cut",
    number_of_plets:"Count",
    piece_in_ton:"Pieces in Ton",
    all_report:"Report of All",
    bp_rolls:"Rolls From BlackPipe",
    qb_rolls:"Rolls From Qandelblbas",
    packets:"Packets",
    whole_roll: "Cut the whole Roll",
    how_many_groups: "Cut into X groups",
    number_of_pieces: "Number of Pieces",
    length: "Length",
    piece_weight: "Piece Weight",
    note: "Note",
    report: "Report",
    pr_weight: "Pedicted Weight",
    product_report: "Producing Report",
    product_name: "Product Name",
    s_packet_qnt: "Standard Packet Quantity",
    spq: "SP Quantity",
    s_packet_w: "Standard Packet Weight",
    s_piece_w: "Standard Piece Weight",
    select_list: "Select list",
    select_line: "Select Line",
    type: "Type",
    qnt: "Quantity",
    weight: "Weight",
    code: "Code",
    made_in: "Made In",
    from: "From",
    date: "Date",
    line: "Line",
    quality: "Quality",
    car_no: "Car Number",
    car_type: "Car Type",
    sell: "Sell",
    sell_to: "Sell to",
    sold_by: "Sold By",
    sold: "Sold",
    driverName: "Driver Name",
    driverPhone: "Driver Phone",
    pipes: "Pieces",
    total_weight: "Total Weight",
    total_qnt: "Total Quantity",
    packet_qnt: "Packet Quantity",
    packet_weight: "Packet Weight",
    thikness: "Thickness",
    width: "Width",
    net_weight: "Net Weight",
    gross_weight: "Gross Weight",
    tLength: "Theory Length",
    aLength: "Actual Length",
    rollQuality: "Roll Quality",
    comment: "Comment",
    added_by: "Added By",
    time: "Time",
    remains: "Remains",
    devid_into: "Divide Into",
    devided_into: "Divided Into",
    real_width: "Real Width",
    slitted_by: "Slitted By",
    roll_code: "Roll Code",
    tarqeq_length: "Thinning Length",
    tarqeq_thikness: "Thinning Thikness",
    hr: "HR",
    gi: "GI",
    cr: "CR",
    deleted_comment: "Deleted Reason",
    deleted_date: "Deleted Date",
    deleted_by: "Deleted By",
    upload_image: "Upload Image",
    tambured_by: "Tambured By",
    role: "Role",
    username: "Username",
    name: "Name",
    phone: "Phone",
    password: "Password",
    reports: "Reports",
    technician: "Technician",
    Report_of: "Report Of",
    from_time: "From Time",
    to_time: "End Time",
    speed: "Speed",
    report_form: "Report Form Header",
    report_list: "Report Types",
    year: "Year",
    none: "None",
    all: "All",
    print: "Print",
    used_spares: "Used Spares",
    usedBy: "Used By",
    gaveTo: "Gave To",
    reset_password: "Reset Password",
    newPassword: "New Password",
    day_series: "Day Series",
    piece_series: "Piece Series",
    actual_weight: "Actual Weight",
    total_real_weight: "Total Real Weight",
    total: "Total",
    //
    reset: "Reset",
    reseted: "Reseted",
    use: "Use",
    used: "Used",
    send: "Send",
    sent: "Sent",
    slit: "Slit",
    slited: "Slitted",
    delete: "Delete",
    deleted: "Deleted",
    edit: "Edit",
    update: "Update",
    updated: "Updated",
    added: "Added",
    cancel: "Cancel",
    search: "Search",
    transfer: "Transfer",
    transferred: "Transferred",
    transfer_to: "Transfer To",
    transferred_to: "Transferred To",
    transferred_by: "Transferred By",
    return: "Return",
    returned: "Returned",
    cut: "Cut",
    add_roll: "Add Roll",
    add_list: "Add List",
    add_product: "Add Product",
    add_wasting: "Add Wasting",
    add_piece: "Add Piece",
    add_spare_list: "Add Spare List",
    add_spare: "Add Spare",
    add_user: "Add User",
    add_plet: "Add Plet",
    //
    on_add_product: "Are you sure you want to Add Product?",
    on_transfer: "Are you sure you want to Transfer?",
    on_produce: "Are you sure you want to Produce?",
    on_add: "Are you sure you want to Add?",
    on_update: "Are you sure you want to Update?",
    on_return: "Are you sure you want to Return?",
    on_tambur: "Are you sure you want to Send to Tambur?",
    on_slit: "Are you sure you want to Slit?",
    on_tarqeq: "Are you sure you want to Tarqeq?",
    on_delete: "Are you sure you want to DELETE?",
    on_delete_per: "Are you sure you want to DELETE PERMANENTLY?",
    on_use: "Are you sure you want to USE?",
    on_reset: "Are you sure you want to Reset your Password?",
    on_cut: "Are you sure you want to Cut this Roll?",
    on_sell: "Are you sure you want to sell?",
    // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    productions: "Productions",
    qandelblbas: "QandelBlbas",
    empty_store: "This Store is Empty",
    not_enough: "Not Enought Production in Store!",
    all_sold: "All Sold Data",
    size: "Size",
    seller: "Seller",
    Data_Not_Found: "Data Not Found",
    page_not_found: "Page Not Found",
    allproducts: "All Products",
    for_order: "For Order and Inquiries",
    // \\\\\\\\\\\\\\\\\\\\\\
  },
};

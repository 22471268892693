import React, { useState } from "react";
import { Button, Table, DatePicker, Space, Spin, Input, Badge } from "antd";
import UpdateRollForm from "./UpdateRollForm";
import AddRollForm from "./AddRollForm";
import { AiTwotoneEdit, AiTwotoneDelete } from "react-icons/ai";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { HTTP } from "../../../HTTPS";
import { useEffect } from "react";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";

const { Search } = Input;

const QRolls = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [rollData, setRollData] = useState([]);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rollsLength, setRollsLength] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});

  const handleUpdateModalClose = () => {
    setUpdateRecord([]);
    setOpenUpdateModal(false);
  };

  const RollColumns = [
    {
      title: "#",
      width: 15,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("is_cut")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.isCut ? (
            <Badge className="mx-1 badge-today" color="red" />
          ) : (
            <Badge className="mx-1 badge-today" color="green" />
          )}
        </span>
      ),
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("width")}`,
      dataIndex: "width",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      dataIndex: "type",
      key: "id",
      width: 50,
    },
    {
      title: `${t("rollQuality")}`,
      dataIndex: "rollQuality",
      key: "id",
      width: 50,
    },
    {
      title: `${t("net_weight")}`,
      dataIndex: "netWeight",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("made_in")}`,
      dataIndex: "madeIn",
      key: "id",
      width: 75,
      render: (record) => <span>{record}</span>,
    },
    {
      title: `${t("date")}`,
      dataIndex: "createdAt",
      key: "id",
      width: 75,
      render: (record) => <span>{moment(record).format("Y.MM.DD HH:mm")}</span>,
    },
    ...(role == "Admin" || role == "Accountant" || role == "QandelBlbas"
      ? [
          {
            dataIndex: "",
            width: 70,
            fixed: "right",
            key: "id",
            render: (record) => (
              <>
                <Button
                  size="small"
                  onClick={() => handleDelete(record.id)}
                  className="my-1"
                  danger
                >
                  <AiTwotoneDelete />{" "}
                  <span className="d-none d-md-inline"> {t("delete")}</span>
                </Button>{" "}
                <Button
                  size="small"
                  onClick={() => {
                    setUpdateRecord(record);
                    setOpenUpdateModal(true);
                  }}
                  type="primary"
                  ghost
                >
                  <AiTwotoneEdit />{" "}
                  <span className="d-none d-md-inline"> {t("edit")}</span>
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];

  const getData = async () => {
    setLoading(true);
    await HTTP.get(
      `/qrolls/all?_isAvailable=1&_limit=${limit}&_page=${currentPage}&_search=${search}&_date=${date}`
    )
      .then((response) => {
        setLoading(false);
        setCount(Math.ceil(response.data.count / limit));
        setRollsLength(response.data.count);
        setRollData(response.data.rows);
      })
      .catch((error) => {
        setLoading(false);
        setRollData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: `${t("on_delete")}`,
      showCancelButton: true,
      confirmButtonText: `${t("delete")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      await HTTP.delete(
        `/qrolls/delete/${id}?_deletedBy=${localStorage.getItem("username")}`
      )
        .then((response) => {
          setLoading(false);
          getData();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${t("deleted")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          setLoading(false);
          setRollData([]);
          if (error.response.status == 401) {
            auth.logout();
          } else if (error.response.status === 404) {
            Swal.fire({
              position: "top-end",
              icon: "info",
              title: "Roll not Found",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            const message = error.response.data.error.message;
            Swal.fire({
              position: "top-end",
              icon: "info",
              title: message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    });
  };

  useEffect(() => {
    getData();
  }, [limit, currentPage, search, date]);

  return (
    <>
      {/* ADD ROLL FORM */}
      {role == "Admin" || role == "Accountant" || role == "QandelBlbas" ? (
        <AddRollForm getData={getData} />
      ) : (
        <></>
      )}

      {/*ROLLS TABLE */}
      <Row>
        <Col md={6}>
          <h4 className="mb-3">
            {t("rolls")} ({rollsLength})
          </h4>
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={RollColumns}
        size="small"
        className="tbs"
        id="rollsTable"
        tableLayout="fixed"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        pagination={{
          onChange: (current, size) => {
            setLimit(size);
            setCurrentPage(current);
          },
          total: Math.floor(count * limit),
          defaultPageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="px-md-4">
              {record.code && (
                <>
                  <span className=" lh-base">{t("code")}: </span>
                  <span className="fw-bold">{record.code}</span>
                  <br />
                </>
              )}
              <span className=" lh-base">{t("added_by")}: </span>
              <span className="fw-bold">{record.addedBy}</span>
              <br />
              <span className=" lh-base">{t("gross_weight")}: </span>
              <span className="fw-bold">
                {record.grossWeight} {t("kg")}
              </span>
              <br />
              <span className=" lh-base">{t("length")}: </span>
              <span className="fw-bold">
                {record.length} {t("m")}
              </span>
              <br />
              {record.addedComment && (
                <>
                  <span className=" lh-base">{t("comment")}: </span>
                  <span className="fw-bold">{record.addedComment}</span>
                  <br />
                </>
              )}
            </div>
          ),
          columnWidth: 12,
        }}
        dataSource={rollData}
      />

      {/* UPDATE MODAL */}
      <Modal
        show={openUpdateModal}
        onHide={handleUpdateModalClose}
        fullscreen
        aria-labelledby="update-modal-title"
        centered
      >
        <Container
          className="pb-5 pb-md-0"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="update-modal-title"> {t("update")} </Modal.Title>
          </Modal.Header>
          <UpdateRollForm
            initialValues={updateRecord}
            handleClose={handleUpdateModalClose}
            getData={getData}
          />
        </Container>
      </Modal>
    </>
  );
};

export default QRolls;

import { DatePicker, Empty, Row, Space, Spin } from "antd";
import { AiFillPrinter } from "react-icons/ai";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Col } from "react-bootstrap";
import moment from "moment/moment";
import { useEffect } from "react";
import { HTTP } from "../../../HTTPS";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Button, TextField } from "@mui/material";
import logo from "../../../assets/imgs/logo.png";

const ITEM_HEIGHT = 50;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4,
      width: 100,
    },
  },
};

const lines = ["101A", "76B", "76C", "51D"];

const Report = () => {
  const componentRef = useRef();
  const { t } = useTranslation();
  const auth = useAuth();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const [length, setLength] = useState(0);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().format("Y-MM-DD"));
  const [selectedLines, setSelectedLines] = useState([]);
  const [techs, setTechs] = useState([
    {
      l101A: "",
      l76B: "",
      l76C: "",
      l51D: "",
    },
  ]);
  const handleTechs = (e)=>{
    setTechs({
      ...techs,
      [e.target.name]: e.target.value
    });
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLines(typeof value === "string" ? value.split(",") : value);
  };

  const getProductsReport = async () => {
    setLoading(true);
    await HTTP.get(`/factorstore/report?_date=${date}&_lines=${selectedLines}`)
      .then((response) => {
        const datas = response.data.rows.reduce((lines, d) => {
          if (!lines[d.line]) lines[d.line] = [];
          lines[d.line].push(d);
          return lines;
        }, {});

        const l101A = datas["101A"]?.reduce((names, d) => {
          if (!names[d.list.name]) names[d.list.name] = [];
          names[d.list.name].push(d);
          return names;
        }, {});
        const l76B = datas["76B"]?.reduce((names, d) => {
          if (!names[d.list.name]) names[d.list.name] = [];
          names[d.list.name].push(d);
          return names;
        }, {});
        const l76C = datas["76C"]?.reduce((names, d) => {
          if (!names[d.list.name]) names[d.list.name] = [];
          names[d.list.name].push(d);
          return names;
        }, {});
        const l51D = datas["51D"]?.reduce((names, d) => {
          if (!names[d.list.name]) names[d.list.name] = [];
          names[d.list.name].push(d);
          return names;
        }, {});
        setLength(response.data.count);
        setAllData({
          l101A,
          l76B,
          l76C,
          l51D,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setLength(0);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (lines.length) {
      getProductsReport();
    }
  }, [date, selectedLines]);
  return (
    <>
      <Row className="d-flex align-items-center mb-4">
        <Col md={5}>
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("line")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedLines}
              onChange={handleChange}
              input={<OutlinedInput label={t("line")} />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {lines.map((name) => (
                <MenuItem key={name} value={name} className="p-0">
                  <Checkbox checked={selectedLines.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col md={2} className="px-1">
          <DatePicker
            size="large"
            placeholder={t("date")}
            onChange={(date, dateString) => {
              if (!dateString) {
                setDate(moment().format("Y-MM-DD"));
              } else {
                setDate(dateString);
              }
            }}
            style={{
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          />
        </Col>
        <Col md={3}></Col>
        <Col md={2}>
          <Button
            variant="outlined"
            disabled={!selectedLines.length}
            onClick={() => {
              handlePrint();
            }}
          >
            <span className={currentLanguageCode == "en" ? "en-f" : "kr-f"}>
              <AiFillPrinter /> {t("print")}
            </span>
          </Button>
        </Col>
      </Row>
      <Row>
        {allData["l101A"] && (
          <Col>
            <TextField
              id="outlined-basic"
              label="l101A"
              name="l101A"
              className="w-100 px-2"
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleTechs(e)
              }}
            />
          </Col>
        )}
        {allData["l76B"] && (
          <Col>
            <TextField
              id="outlined-basic"
              label="l76B"
              name="l76B"
              className="w-100 px-2"
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleTechs(e)
              }}
            />
          </Col>
        )}
        {allData["l76C"] && (
          <Col>
            <TextField
              id="outlined-basic"
              label="l76C"
              name="l76C"
              className="w-100 px-2"
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleTechs(e)
              }}
            />
          </Col>
        )}
        {allData["l51D"] && (
          <Col>
            <TextField
              id="outlined-basic"
              label="l51D"
              name="l51D"
              className="w-100 px-2"
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleTechs(e)
              }}
            />
          </Col>
        )}
      </Row>
      {/* Page */}
      <div
        ref={componentRef}
        style={{
          width: "100%",
          height: "100%",
        }}
        className={currentLanguageCode == "en" ? "ltr" : "rtl"}
      >
        <div className="mt-1 freport">
          <div className="top-line text-center mt-2">{t("product_report")}</div>
          <Row className="logo-name align-items-center text-center">
            <Col md={4}>
              کارگەی بلاك <span className="text-danger">پایپ</span> <br /> بۆ
              بەرمهێنانی هەموو جۆرە پرۆفیلێک
            </Col>
            <Col md={4}>
              <img src={logo} className="img-fluid" alt="" />{" "}
            </Col>
            <Col md={4}>
              مصنع بلاك <span className="text-danger">بایب</span> لانتاج انابيب
              الحديدية
              <br />
              Black <span className="text-danger">Pipe</span> Factory for
              Manufacturing Iron Pipes
            </Col>
          </Row>
          <div className="date text-center my-4">
            <span className="n">{t("date")}</span>
            <span className="d">{date}</span>
          </div>

          {loading && (
            <div className="text-center">
              <Space size="middle">
                <Spin size="large" />
              </Space>
            </div>
          )}
          {length == 0 ? (
            <>
              <Empty />
            </>
          ) : (
            <Row>
              {allData["l101A"] && (
                <Col className="px-1">
                  {Object.keys(allData["l101A"]).map((l, key) => {
                    return (
                      <div key={l} className="mb-3">
                        <div className="header">
                          <div className="d-flex">
                            <div className="lef">{t("technician")}</div>
                            <div className="rig">
                              {techs.l101A}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("line")}</div>
                            <div className="rig">101A</div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("speed")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("time")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                        </div>
                        <Row className="data mt-1">
                          <Col xs={4} className="px-1">
                            <Row className="d-block bg-db mt-1">
                              {t("product_name")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("quality")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pieces")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("piece_weight")}{" "}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pr_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("total_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("note")}
                            </Row>
                          </Col>
                          <Col xs={8}>
                            <div className="d-block bg-lb mt-1">
                              {l} - {allData["l101A"][l][0].list.type}
                            </div>
                            <Row>
                              {Array.from({ length: 3 }, (_, index) => {
                                return (
                                  <Col
                                    key={index}
                                    className={`${index == 1 && "px-1"}`}
                                    xs={4}
                                  >
                                    <Row
                                      className={`d-block mt-1 ${
                                        index == 0 && "q1"
                                      } ${index == 1 && "q2"} ${
                                        index == 2 && "q3"
                                      }`}
                                    >
                                      {index + 1}
                                    </Row>
                                    <>
                                      {allData["l101A"][l]
                                        .filter((f) => f.quality == index + 1)
                                        .map((fq) => {
                                          return (
                                            <div key={fq.id}>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt || "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.list.standartPieceWeight ||
                                                  "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt *
                                                  fq.list.standartPieceWeight}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetWeight || "0"}
                                              </Row>
                                            </div>
                                          );
                                        })}
                                    </>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="mt-1 bg-lg">
                              <input
                                type="text"
                                className="freportinpute text-start w-100"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              )}
              {allData["l76B"] && (
                <Col className="px-1">
                  {Object.keys(allData["l76B"]).map((l, key) => {
                    return (
                      <div key={l} className="mb-3">
                        <div className="header">
                          <div className="d-flex">
                            <div className="lef">{t("technician")}</div>
                            <div className="rig">
                              {techs.l76B}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("line")}</div>
                            <div className="rig">76B</div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("speed")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("time")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                        </div>
                        <Row className="data mt-1">
                          <Col xs={4} className="px-1">
                            <Row className="d-block bg-db mt-1">
                              {t("product_name")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("quality")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pieces")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("piece_weight")}{" "}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pr_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("total_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("note")}
                            </Row>
                          </Col>
                          <Col xs={8}>
                            <div className="d-block bg-lb mt-1">
                              {l} - {allData["l76B"][l][0].list.type}
                            </div>
                            <Row>
                              {Array.from({ length: 3 }, (_, index) => {
                                return (
                                  <Col
                                    key={index}
                                    className={`${index == 1 && "px-1"}`}
                                    xs={4}
                                  >
                                    <Row
                                      className={`d-block mt-1 ${
                                        index == 0 && "q1"
                                      } ${index == 1 && "q2"} ${
                                        index == 2 && "q3"
                                      }`}
                                    >
                                      {index + 1}
                                    </Row>
                                    <>
                                      {allData["l76B"][l]
                                        .filter((f) => f.quality == index + 1)
                                        .map((fq) => {
                                          return (
                                            <div key={fq.id}>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt || "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.list.standartPieceWeight ||
                                                  "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt *
                                                  fq.list.standartPieceWeight}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetWeight || "0"}
                                              </Row>
                                            </div>
                                          );
                                        })}
                                    </>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="mt-1 bg-lg">
                              <input
                                type="text"
                                className="freportinpute text-start w-100"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              )}
              {allData["l76C"] && (
                <Col className="px-1">
                  {Object.keys(allData["l76C"]).map((l, key) => {
                    return (
                      <div key={l} className="mb-3">
                        <div className="header">
                          <div className="d-flex">
                            <div className="lef">{t("technician")}</div>
                            <div className="rig">
                              {techs.l76C}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("line")}</div>
                            <div className="rig">76C</div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("speed")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("time")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                        </div>
                        <Row className="data mt-1">
                          <Col xs={4} className="px-1">
                            <Row className="d-block bg-db mt-1">
                              {t("product_name")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("quality")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pieces")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("piece_weight")}{" "}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pr_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("total_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("note")}
                            </Row>
                          </Col>
                          <Col xs={8}>
                            <div className="d-block bg-lb mt-1">
                              {l} - {allData["l76C"][l][0].list.type}
                            </div>
                            <Row>
                              {Array.from({ length: 3 }, (_, index) => {
                                return (
                                  <Col
                                    key={index}
                                    className={`${index == 1 && "px-1"}`}
                                    xs={4}
                                  >
                                    <Row
                                      className={`d-block mt-1 ${
                                        index == 0 && "q1"
                                      } ${index == 1 && "q2"} ${
                                        index == 2 && "q3"
                                      }`}
                                    >
                                      {index + 1}
                                    </Row>
                                    <>
                                      {allData["l76C"][l]
                                        .filter((f) => f.quality == index + 1)
                                        .map((fq) => {
                                          return (
                                            <div key={fq.id}>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt || "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.list.standartPieceWeight ||
                                                  "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt *
                                                  fq.list.standartPieceWeight}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetWeight || "0"}
                                              </Row>
                                            </div>
                                          );
                                        })}
                                    </>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="mt-1 bg-lg">
                              <input
                                type="text"
                                className="freportinpute text-start w-100"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              )}
              {allData["l51D"] && (
                <Col className="px-1">
                  {Object.keys(allData["l51D"]).map((l, key) => {
                    return (
                      <div key={l} className="mb-3">
                        <div className="header">
                          <div className="d-flex">
                            <div className="lef">{t("technician")}</div>
                            <div className="rig">
                              {techs.l51D}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("line")}</div>
                            <div className="rig">51D</div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("speed")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="lef">{t("time")}</div>
                            <div className="rig">
                              <input
                                type="text"
                                className="freportinpute text-start"
                              />
                            </div>
                          </div>
                        </div>
                        <Row className="data mt-1">
                          <Col xs={4} className="px-1">
                            <Row className="d-block bg-db mt-1">
                              {t("product_name")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("quality")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pieces")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("piece_weight")}{" "}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("pr_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("total_weight")}
                            </Row>
                            <Row className="d-block bg-dg mt-1">
                              {t("note")}
                            </Row>
                          </Col>
                          <Col xs={8}>
                            <div className="d-block bg-lb mt-1">
                              {l} - {allData["l51D"][l][0].list.type}
                            </div>
                            <Row>
                              {Array.from({ length: 3 }, (_, index) => {
                                return (
                                  <Col
                                    key={index}
                                    className={`${index == 1 && "px-1"}`}
                                    xs={4}
                                  >
                                    <Row
                                      className={`d-block mt-1 ${
                                        index == 0 && "q1"
                                      } ${index == 1 && "q2"} ${
                                        index == 2 && "q3"
                                      }`}
                                    >
                                      {index + 1}
                                    </Row>
                                    <>
                                      {allData["l51D"][l]
                                        .filter((f) => f.quality == index + 1)
                                        .map((fq) => {
                                          return (
                                            <div key={fq.id}>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt || "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.list.standartPieceWeight ||
                                                  "0"}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetQnt *
                                                  fq.list.standartPieceWeight}
                                              </Row>
                                              <Row className="d-block bg-lg mt-1">
                                                {fq.packetWeight || "0"}
                                              </Row>
                                            </div>
                                          );
                                        })}
                                    </>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="mt-1 bg-lg">
                              <input
                                type="text"
                                className="freportinpute text-start w-100"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Report;

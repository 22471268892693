import { Form, Formik } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button, DatePicker } from "antd";
import FormikController from "../Forms/FormikController";
import * as Yup from "yup";
import { HTTP } from "../../HTTPS";
import { useAuth } from "../auth";
import Swal from "sweetalert2";

const BulkTambur = ({ pieceValues, getPieces,
  getTambureds, handleClose }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const lines = [
    { key: "101A", value: "101A" },
    { key: "76B", value: "76B" },
    { key: "76C", value: "76C" },
    { key: "51D", value: "51D" },
  ];

  const initialValues = {
    pieceValues,
    realWeight: "",
    line: localStorage.getItem("line") || "101A",
    tamburedBy: localStorage.getItem("username"),
    tamburedComment: "",
    img: "",
    createdAt: "",
  };
  const validationSchema = Yup.object({
    realWeight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
  });
  const handleSubmit = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    Swal.fire({
      title: `${t("on_tambur")}`,
      showCancelButton: true,
      confirmButtonText: `${t("send")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HTTP.post(`/tambur/bulkcreate`, values)
          .then((response) => {
            getPieces();
            getTambureds();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("sent")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.setSubmitting(false);
            handleClose();
          })
          .catch((error) => {
            console.log(error);
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      }
    });
  };
  return (
    <Modal.Body>
      <Row className="px-2">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formik) => (
            <>
              <Form className="p-2">
                <Row className="">
                  <Col md={6}>
                  <FormikController
                      className={`${
                        localStorage.getItem("role") == "Tambur" ? "d-none" : ""
                      }`}
                      control="select"
                      formik={formik}
                      value={formik.values.line}
                      error={formik.errors.line}
                      touched={formik.touched.line}
                      options={lines}
                      name="line"
                      label={t("line")}
                    />
                  </Col>
                </Row>
                <Row className="">
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.realWeight}
                      error={formik.errors.realWeight}
                      touched={formik.touched.realWeight}
                      type="number"
                      name="realWeight"
                      label={t("actual_weight")}
                      step={0.01}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    {role == "Admin" || role == "Accountant" ? (
                      <DatePicker
                        placeholder={t("date")}
                        size="large"
                        className="w-100"
                        onChange={(date, dateString) => {
                          formik.setFieldValue("createdAt", dateString);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormikController
                      control="textarea"
                      formik={formik}
                      value={formik.values.tamburedComment}
                      error={formik.errors.tamburedComment}
                      touched={formik.touched.tamburedComment}
                      name="tamburedComment"
                      label={t("comment")}
                    />
                  </Col>
                </Row>
                <Button
                  className="mt-2"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {t("tambur")}
                </Button>
              </Form>
            </>
          )}
        </Formik>
      </Row>
    </Modal.Body>
    // <Formik
    //   initialValues={initialValues}
    //   //   validationSchema={validationSchema}
    //   //   onSubmit={handleUpdate}
    //   validateOnMount
    // >
    //   {(formik) => (
    //     <Form className="w-100 p-2">
    //       <Modal.Body className="bulktamburform">
           
    //       </Modal.Body>
    //     </Form>
    //   )}
    // </Formik>
  );
};

export default BulkTambur;

import React, { useState } from "react";
import { Table, DatePicker, Space, Spin, Input, Button } from "antd";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { HTTP } from "../../../HTTPS";
import { useEffect } from "react";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import AddPlet from "./AddPlet";
import SellPlets from "./SellPlets";
import { AiOutlineRollback } from "react-icons/ai";
import EachPlet from "./EachPlet";

const { Search } = Input;

const Plets = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [pletData, setPletData] = useState([]);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pletsLength, setPletsLength] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  const [spletData, setSPletData] = useState([]);
  const [slimit, setSLimit] = useState(25);
  const [scount, setSCount] = useState(0);
  const [scurrentPage, setSCurrentPage] = useState(1);
  const [spletsLength, setSPletsLength] = useState(0);
  const [ssearch, setSSearch] = useState("");
  const [sloading, setSLoading] = useState(false);
  const [sdate, setSDate] = useState("");
  const [sellable, Setsellable] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleAddSelectedRows = (id) => {
    var include = false;
    for (var i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i] == id) {
        include = true;
      }
    }
    if (!include) {
      setSelectedProducts([...selectedProducts, id]);
      Setsellable(true);
    }
  };
  const handleRemoveSelectedRows = (id) => {
    var include = false;
    for (var i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i] == id) {
        include = true;
      }
    }
    if (include) {
      for (var j = 0; j < selectedProducts.length; j++) {
        if (selectedProducts[j] == id) {
          selectedProducts.splice(j, 1);
        }
      }
      if (!selectedProducts.length) {
        Setsellable(false);
      }
    }
  };

  const [openSellFormModal, setOpenSellFormModal] = useState(false);
  const handleSellModalClose = () => {
    getData();
    getSData();
    setOpenSellFormModal(false);
  };
  const pletsColumns = [
    {
      title: "#",
      width: 50,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("length")}`,
      key: "len",
      width: 100,
      render: (record) => (
        <span>
          {record.len} {t("m")}
        </span>
      ),
    },
    {
      title: `${t("weight")}`,
      key: "weight",
      width: 100,
      render: (record) => (
        <span>
          {record.weight} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 100,
      render: (record) => (
        <span>
          {record.qroll.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      key: "id",
      width: 100,
      render: (record) => <span>{record.qroll.type}</span>,
    },
    {
      title: `${t("number_of_plets")}`,
      key: "id",
      width: 75,
      render: (record) => {
        return pletData.count.map((i) => {
          if (
            i.weight == record.weight &&
            i.thikness == record.qroll.thikness &&
            i.type == record.qroll.type &&
            i.width == record.qroll.width
          ) {
            return <span>{i.count}</span>;
          }
        });
      },
    },
  ];
  const SPletsColumns = [
    {
      title: "#",
      width: 15,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("to")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.soldplet.to}</span>,
    },
    {
      title: `${t("length")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.len} {t("m")}
        </span>
      ),
    },
    {
      title: `${t("weight")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.weight} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.qroll.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.qroll.type}</span>,
    },
    {
      title: `${t("width")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.qroll.width}</span>,
    },
    {
      title: `${t("sent_by")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.soldplet.sentBy} </span>,
    },
    {
      title: `${t("date")}`,
      key: "id",
      width: 75,
      render: (record) => (
        <span>{moment(record.soldplet.createAt).format("Y.MM.DD HH:mm")}</span>
      ),
    },
    ...(role == "Admin" || role == "Accountant" || role == "QandelBlbas"
      ? [
          {
            dataIndex: "",
            width: 75,
            fixed: "right",
            key: "id",
            render: (record) => (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    handleReturn(record);
                  }}
                  type="primary"
                  ghost
                >
                  <AiOutlineRollback />{" "}
                  <span className="d-none d-md-inline"> {t("return")}</span>
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];
  const getData = async () => {
    setPletData([]);
    setLoading(true);
    await HTTP.get(
      `/plets/grouped?_sold=0&_limit=${limit}&_page=${currentPage}&_search=${search}&_date=${date}`
    )
      .then((response) => {
        setLoading(false);
        setCount(Math.ceil(response.data.rows.length / limit));
        setPletsLength(response.data.rows.length);
        setPletData(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setPletData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const getSData = async () => {
    setSPletData([]);
    setSLoading(true);
    await HTTP.get(
      `/plets/all?_sold=1&_limit=${slimit}&_page=${scurrentPage}&_search=${ssearch}&_date=${sdate}`
    )
      .then((response) => {
        setSLoading(false);
        setSCount(Math.ceil(response.data.count / slimit));
        setSPletsLength(response.data.count);
        setSPletData(response.data.rows);
      })
      .catch((error) => {
        setSLoading(false);
        setSPletData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };

  const handleReturn = (record) => {
    const { id } = record;
    Swal.fire({
      title: `${t("on_return")}`,
      showCancelButton: true,
      confirmButtonText: `${t("return")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HTTP.post(
          `/plets/return/${id}?_returnedBy=${localStorage.getItem("username")}`
        )
          .then((response) => {
            getSData();
            getData();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("returned")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    getData();
    getSData();
  }, [limit, currentPage, search, date, slimit, scurrentPage, ssearch, sdate]);

  return (
    <>
      {/* ADD LIST */}
      {role == "Admin" || role == "Accountant" || role == "QandelBlbas" ? (
        <AddPlet getData={getData} />
      ) : (
        <></>
      )}
      <Row>
        <Col md={4}>
          <h4 className="mb-3">
            {t("plets")} ({pletsLength})
          </h4>
        </Col>
        <Col md={2}>
          {role == "Admin" || role == "Accountant" || role == "QandelBlbas" ? (
            <Button
              onClick={() => {
                setOpenSellFormModal(true);
              }}
              disabled={!sellable}
              type="primary"
              className="my-1 my-md-2 w-100"
              ghost
            >
              {t("sell")}
            </Button>
          ) : (
            <></>
          )}
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={pletsColumns}
        size="small"
        className="tbs"
        id="rollsTable"
        tableLayout="fixed"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        pagination={{
          onChange: (current, size) => {
            setLimit(size);
            setCurrentPage(current);
          },
          total: Math.floor(count * limit),
          defaultPageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <EachPlet
              record={record}
              handleAddSelectedRows={handleAddSelectedRows}
              handleRemoveSelectedRows={handleRemoveSelectedRows}
              getData={getData}
            />
          ),
        }}
        dataSource={pletData.rows}
      />

      {/* SOLD PLETS */}
      <Row>
        <Col md={6}>
          <h4 className="mb-3">
            {t("sold_plets")} ({spletsLength})
          </h4>
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setSDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={SPletsColumns}
        size="small"
        className="tbs"
        id="rollsTable"
        tableLayout="fixed"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: sloading,
        }}
        pagination={{
          onChange: (current, size) => {
            setSLimit(size);
            setSCurrentPage(current);
          },
          total: Math.floor(scount * slimit),
          defaultPageSize: slimit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="px-md-4">
              <span className=" lh-base">{t("driverName")}: </span>
              <span className="fw-bold">{record.soldplet.driverName}</span>
              <br />
              <span className=" lh-base">{t("driverPhone")}: </span>
              <span className="fw-bold">{record.soldplet.driverPhone}</span>
              <br />
              <span className=" lh-base">{t("car_no")}: </span>
              <span className="fw-bold">{record.soldplet.carNo}</span>
              <br />
              <span className=" lh-base">{t("car_type")}: </span>
              <span className="fw-bold">{record.soldplet.carType}</span>
              <br />
            </div>
          ),
          columnWidth: 12,
        }}
        dataSource={spletData}
      />
      {/* SELL MODAL */}
      <Modal
        show={openSellFormModal}
        onHide={handleSellModalClose}
        fullscreen
        aria-labelledby="update-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="update-modal-title">{t("sell")}</Modal.Title>
        </Modal.Header>
        <Container
          className="pb-5 pb-md-0"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <SellPlets
            selectedRows={selectedProducts}
            setSelectedRows={setSelectedProducts}
            handleClose={handleSellModalClose}
            getData={getData}
          />
        </Container>
      </Modal>
    </>
  );
};

export default Plets;

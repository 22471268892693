import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikController from "../../Forms/FormikController";
import TextError from "../../Forms/TextError";
import { HTTP } from "../../../HTTPS";
import Swal from "sweetalert2";
import { useAuth } from "../../auth";
import { Button } from "antd";

const CutModal = ({ rollData, handleClose, getData, getCutData }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [whole, setWhole] = useState(false);

  const prd = {
    length: "",
    weight: "",
    number: "",
    rollId: rollData.id,
  };

  const initialValues = {
    rollId: rollData.id,
    whole: false,
    cutter: localStorage.getItem("username"),
    group: "",
    groups: [],
    remains: "",
  };
  const validationSchema = Yup.object({
    groups: Yup.array(
      Yup.object({
        length: Yup.number()
          .positive("Must be a positive Number")
          .required("Required"),
        weight: Yup.number()
          .positive("Must be a positive Number")
          .required("Required"),
      })
    ),
  });
  const handleAdd = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    Swal.fire({
      title: `${t("on_cut")}`,
      showCancelButton: true,
      confirmButtonText: `${t("cut")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HTTP.post(`/qrolls/cut`, values)
          .then((response) => {
            getData();
            getCutData();
            Swal.fire({
              position: "top",
              icon: "success",
              title: `${t("cut")}`,
              showConfirmButton: false,
              timer: 3000,
            });
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm();
            handleClose();
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      } else {
        onSubmitProps.setSubmitting(false);
      }
    });
  };
  return (
    <Container className="my-4">
      <Row>
        <Col mb={4}>
          <span className=" lh-base">{t("code")}: </span>
          <span className="fw-bold">{rollData.code}</span>
          <br />
          <span className=" lh-base">{t("type")}: </span>
          <span className="fw-bold">{rollData.type}</span>
          <br />
          <span className=" lh-base">{t("made_in")}: </span>
          <span className="fw-bold text-capitalize">{rollData.madeIn}</span>
        </Col>
        <Col mb={4}>
          <span className=" lh-base">{t("thikness")}: </span>
          <span className="fw-bold">{rollData.thikness}</span>
          <br />
          <span className=" lh-base">{t("width")}: </span>
          <span className="fw-bold">{rollData.width}</span>
          <br />
          {rollData.length ? (
            <>
              <span className=" lh-base">{t("length")}: </span>
              <span className="fw-bold">{rollData.length}</span>
              <br />
            </>
          ) : (
            <></>
          )}
        </Col>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAdd}
          validateOnMount
        >
          {(formik) => (
            <div className="cutFormCard mt-2 px-4">
              <Form className="w-100 mb-3">
                {!initialValues.length ? (
                  <Row className="mb-3">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.Whole}
                            name={`whole`}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "whole",
                                event.target.checked
                              );
                              setWhole(event.target.checked);
                            }}
                          />
                        }
                        label={t("whole_roll")}
                      />
                    </FormGroup>
                  </Row>
                ) : (
                  <></>
                )}
                <Row className="mb-3">
                  <Col md={6}>
                    <TextField
                      direction="ltr"
                      size="small"
                      className="input-bg"
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      label={t("how_many_groups")}
                      name="group"
                      value={formik.values.group || ""}
                      error={
                        formik.touched.group && Boolean(formik.errors.group)
                      }
                      onBlur={() => formik.setFieldTouched("group", true)}
                      onChange={(e) => {
                        formik.setFieldValue(`group`, e.target.value);
                        let x = [];
                        Array.from({
                          length: e.target.value,
                        }).map((_, i) => {
                          x.push(prd);
                        });
                        formik.setFieldValue(`groups`, x);
                      }}
                    />
                    <ErrorMessage name={"group"} component={TextError} />
                  </Col>
                </Row>

                <FieldArray name="groups mb-4">
                  {({ push, remove }) => {
                    return (
                      <>
                        {formik.values.groups.map((_, index) => (
                          <Container fluid className="pt-1" key={index}>
                            <Row>
                              <Col sm={3} className="mb-2">
                                <FormikController
                                  control="input"
                                  formik={formik}
                                  value={formik.values.groups[index].number}
                                  type="number"
                                  name={`groups.${index}.number`}
                                  label={t("number_of_pieces")}
                                />
                              </Col>
                              <Col sm={3} className="mb-2">
                                <>
                                  <div className="mb-3">
                                    <TextField
                                      direction="ltr"
                                      type="number"
                                      size="small"
                                      className="input-bg"
                                      fullWidth
                                      inputProps={{
                                        min: 0,
                                      }}
                                      variant="outlined"
                                      label={t("length")}
                                      name={`groups.${index}.length`}
                                      value={
                                        formik.values.groups[index].length || ""
                                      }
                                      error={
                                        formik.touched && Boolean(formik.error)
                                      }
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `groups.${index}.length`,
                                          e.target.value
                                        );
                                        if (e.target.value > 0) {
                                          formik.setFieldValue(
                                            `groups.${index}.weight`,
                                            Number(
                                              e.target.value *
                                                (rollData.width / 100) *
                                                rollData.thikness *
                                                7.85
                                            ).toFixed(2)
                                          );
                                        }
                                      }}
                                      onBlur={() =>
                                        formik.setFieldTouched(
                                          `groups.${index}.length`,
                                          true
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`groups.${index}.length`}
                                      component={TextError}
                                    />
                                  </div>
                                </>
                              </Col>
                              <Col sm={3} className="mb-2">
                                <FormikController
                                  control="input"
                                  formik={formik}
                                  value={formik.values.groups[index].weight}
                                  type="number"
                                  name={`groups.${index}.weight`}
                                  label={t("weight")}
                                  disabled
                                />
                              </Col>
                            </Row>
                          </Container>
                        ))}
                      </>
                    );
                  }}
                </FieldArray>
                {whole && (
                  <Col sm={3} className="mb-2">
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.remains}
                      error={formik.errors.remains}
                      touched={formik.touched.remains}
                      type="number"
                      name={`remains`}
                      label={t("length")}
                      step={0.01}
                    />
                  </Col>
                )}
                <div className="w-100 ">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("cut")}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default CutModal;

import React, { useState } from "react";
import { Button } from "antd";
import { Formik, Form } from "formik";
import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { HTTP } from "../../../HTTPS";
import FormikController from "../../Forms/FormikController";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";

const SellPlets = ({ selectedRows, handleClose, setSelectedRows, getData }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const initialValues = {
    to: "",
    sentBy: localStorage.getItem("username"),
    carNo: "",
    carType: "",
    driverName: "",
    driverPhone: "",
    ids: selectedRows,
  };

  const validationSchema = Yup.object({
    to: Yup.string().required("Required"),
    driverName: Yup.string().required("Required"),
    carNo: Yup.string().required("Required"),
  });
  const handleAdd = (values, onSubmitProps) => {
    Swal.fire({
      title: `${t("on_sell")}`,
      showCancelButton: true,
      confirmButtonText: `${t("sell")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      onSubmitProps.setSubmitting(true);
      if (result.isConfirmed) {
        await HTTP.post(`/plets/sell`, values)
          .then((response) => {
            getData();
            setSelectedRows([]);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("sold")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            handleClose();
          })
          .catch((error) => {
            getData();
            setSelectedRows([]);
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      }
      onSubmitProps.setSubmitting(false);
    });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAdd}
        validateOnMount
      >
        {(formik) => (
          <div className="py-5 d-flex justify-content-center">
            <Form className="w-100 mb-3">
              <div>
                <Row className="mb-4">
                  <Col md={4}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.to}
                      error={formik.errors.to}
                      touched={formik.touched.to}
                      type="text"
                      name="to"
                      label={t("sell_to")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.driverName}
                      error={formik.errors.driverName}
                      touched={formik.touched.driverName}
                      type="text"
                      name="driverName"
                      label={t("driverName")}
                    />
                  </Col>
                  <Col md={4}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.driverPhone}
                      error={formik.errors.driverPhone}
                      touched={formik.touched.driverPhone}
                      type="text"
                      name="driverPhone"
                      label={t("driverPhone")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.carNo}
                      error={formik.errors.carNo}
                      touched={formik.touched.carNo}
                      type="text"
                      name="carNo"
                      label={t("car_no")}
                    />
                  </Col>
                  <Col md={4}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.carType}
                      error={formik.errors.carType}
                      touched={formik.touched.carType}
                      type="text"
                      name="carType"
                      label={t("car_type")}
                    />
                  </Col>
                </Row>
                <div className="w-100">
                  <Button
                    type="primary"
                    htmlType="submit"
                    variant="outline-primary"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("sell")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SellPlets;

import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/auth";
import Dashboard from "./components/Dashboard";
import Rolls from "./components/Rolls/Rolls";
import Sliters from "./components/Sliter/Sliters";
import Login from "./components/Login";
import NoMatch from "./components/NoMatch";
import SideMenu from "./components/SideMenu";
import AuthRequire from "./components/AuthRequire";
import Pieces from "./components/Pieces/Pieces";
import Tambur from "./components/Tambur/Tambur";
import Users from "./components/Users/Users";
import Remainings from "./components/Remainings.jsx/Remainings";
import Lists from "./components/FactorStore/Lists/Lists";
import Tarqeq from "./components/Tarqeq/Tarqeq";
import Reports from "./components/Reports/Reports";
import RoleRequire from "./components/RoleRequire";
import Spare from "./components/Spare/Spare";
import Activity from "./components/Activities/Activity";
import Produce from "./components/FactorStore/Produce/Produce";
import Store from "./components/FactorStore/Store/Store";
import BpStore from "./components/BpStore/Store/BpStore";
import QLists from "./components/QbStore/Lists/QLists";
import AddProduct from "./components/QbStore/AddProduct/AddProduct";
import QStore from "./components/QbStore/QStore/QStore";
import BpAddProduct from "./components/BpStore/AddProduct/BpAddProduct";
import Transfers from "./components/FactorStore/Transfers/Transfers";
import BpTransfers from "./components/BpStore/Transfers/BpTransfers";
import QbTransfers from "./components/QbStore/Transfers/QbTransfers";
import QRolls from "./components/QbStore/Rolls/QRolls";
import CutRolls from "./components/QbStore/CutRolls/CutRolls";
import Plets from "./components/QbStore/Plets/Plets";

import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style.css"
import "./App.css";
import Report from "./components/FactorStore/Report/Report";
import ReportAll from "./components/ReportAll/ReportAll";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="/"
          element={
            <AuthRequire>
              <SideMenu />
            </AuthRequire>
          }
        >
          {/* Dashboard */}
          <Route
            element={
              <RoleRequire
                allowedRoles={[
                  "Admin",
                  "Accountant",
                  "Watcher",
                  "QandelBlbas",
                  "Store",
                ]}
              />
            }
          >
            <Route index element={<Dashboard />} />
          </Route>
          {/* Reports */}
          <Route
            element={<RoleRequire allowedRoles={["Admin", "Accountant"]} />}
          >
            <Route path="reports" element={<Reports />} />
            <Route path="activity" element={<Activity />} />
            <Route path="spare" element={<Spare />} />
          </Route>
          {/* Rolls */}
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "Enter", "Watcher"]}
              />
            }
          >
            <Route path="rolls" element={<Rolls />} />
          </Route>

          {/* Slitter */}
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "Sliter", "Watcher"]}
              />
            }
          >
            <Route path="sliter" element={<Sliters />} />
            <Route path="pieces" element={<Pieces />} />
            <Route path="remainings" element={<Remainings />} />
          </Route>
          <Route
            element={
              <RoleRequire allowedRoles={["Admin", "Accountant", "Watcher"]} />
            }
          >
            <Route path="tarqeq" element={<Tarqeq />} />
          </Route>
          {/* Tambure */}
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "Watcher", "Tambur"]}
              />
            }
          >
            <Route path="tambur" element={<Tambur />} />
          </Route>
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "Store", "QandelBlbas"]}
              />
            }
          >
            <Route path="bp_addproduct" element={<BpAddProduct />} />
          </Route>
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "Store", "Watcher"]}
              />
            }
          >
            <Route path="factorystore" element={<Store />} />
            <Route path="bpstore" element={<BpStore />} />
            <Route path="f_transfers" element={<Transfers />} />
            <Route path="bp_transfers" element={<BpTransfers />} />
            <Route path="f_report" element={<Report />} />
          </Route>
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "QandelBlbas", "Watcher"]}
              />
            }
          >
            <Route path="qbstore" element={<QStore />} />
            <Route path="qb_rolls" element={<QRolls />} />
            <Route path="plets" element={<Plets />} />
            <Route path="qb_transfers" element={<QbTransfers />} />
            <Route path="all_report" element={<ReportAll />} />
          </Route>
          <Route
            element={
              <RoleRequire
                allowedRoles={["Admin", "Accountant", "QandelBlbas"]}
              />
            }
          >
            <Route path="qb_addproduct" element={<AddProduct />} />
            <Route path="qb_lists" element={<QLists />} />
            <Route path="cut_rolls" element={<CutRolls />} />
          </Route>
          <Route
            element={
              <RoleRequire allowedRoles={["Admin", "Accountant", "Store"]} />
            }
          >
            <Route path="f_lists" element={<Lists />} />
            <Route path="produce" element={<Produce />} />
          </Route>
          {/* Users Only Admin Can See, Edit, and Update */}
          <Route element={<RoleRequire allowedRoles={["Admin"]} />}>
            <Route path="users" element={<Users />} />
          </Route>
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;

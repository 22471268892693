import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { useState } from "react";
import moment from "moment/moment";
import { Space, Spin, Table } from "antd";
import { useEffect } from "react";
import { HTTP } from "../../../HTTPS";
import Swal from "sweetalert2";

const EachPlet = ({
  record,
  getData,
  handleAddSelectedRows,
  handleRemoveSelectedRows,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [eachData, setEachData] = useState([]);
  const [loading, setLoading] = useState(false);

  const eachPletsColumns = [
    {
      title: `${t("length")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.len} {t("m")}
        </span>
      ),
    },
    {
      title: `${t("weight")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.weight} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("roll_code")}`,
      key: "id",
      width: 75,
      render: (record) => <span>{record.qroll.code}</span>,
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.qroll.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.qroll.type}</span>,
    },
    {
      title: `${t("width")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.qroll.width}</span>,
    },
    {
      title: `${t("made_in")}`,
      key: "id",
      width: 50,
      render: (record) => <span>{record.qroll.madeIn} </span>,
    },
    {
      title: `${t("date")}`,
      dataIndex: "createdAt",
      key: "id",
      width: 75,
      render: (record) => <span>{moment(record).format("Y.MM.DD HH:mm")}</span>,
    },
  ];
  const rowSelection = {
    onSelect: (record, selected) => {
      if (selected) {
        handleAddSelectedRows(record.id);
      } else if (!selected) {
        handleRemoveSelectedRows(record.id);
      }
    },
  };
  const getEach = async () => {
    setLoading(true);
    await HTTP.get(
      `/plets/eachgrouped?_sold=false&_thikness=${record.qroll.thikness}&_width=${record.qroll.width}&_weight=${record.weight}&_type=${record.qroll.type}`
    )
      .then((response) => {
        setLoading(false);
        setEachData(response.data.rows);
      })
      .catch((error) => {
        setLoading(false);
        setEachData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getEach();
  }, []);
  return (
    <div className="px-5">
      <Table
        columns={eachPletsColumns}
        size="small"
        className="tbss  my-3"
        id="rollsTable"
        rowKey="id"
        pagination={false}
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          ...rowSelection,
          getCheckboxProps: () => ({
            className: `${
              role == "Admin" || role == "Accountant" || role == "QandelBlbas"
                ? ""
                : "d-none"
            }`,
          }),
        }}
        dataSource={eachData}
      />
    </div>
  );
};

export default EachPlet;

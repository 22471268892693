import React from "react";
import { Button, DatePicker } from "antd";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import FormikController from "../../Forms/FormikController";
import Swal from "sweetalert2";
import { HTTP } from "../../../HTTPS";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";

const AddRollForm = ({ getData }) => {
  const { t } = useTranslation();
  const role = localStorage.getItem("role");
  const auth = useAuth();
  const initialValues = {
    code: "",
    type: "HR",
    grossWeight: "",
    netWeight: "",
    addedBy: localStorage.getItem("username"),
    rollQuality: "A",
    length: "",
    width: "",
    thikness: "",
    madeIn: "china",
    addedComment: "",
    createdAt: "",
  };
  const validationSchema = Yup.object({
    code: Yup.string(),
    type: Yup.string().required("Required"),
    netWeight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    width: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    thikness: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
  });
  const types = [
    { key: "hr", value: "HR" },
    { key: "gi", value: "GI" },
    { key: "gr", value: "CR" },
  ];
  const rollQ = [
    { key: "A", value: "A" },
    { key: "B", value: "B" },
  ];
  const countries = [
    { key: "cn", value: "china" },
    { key: "iq", value: "iraq" },
    { key: "ir", value: "iran" },
    { key: "tr", value: "turkey" },
    { key: "ru", value: "russia" },
    { key: "ua", value: "ukraine" },
    { key: "eu", value: "europe" },
    { key: "jo", value: "jordan" },
  ];
  const handleAdd = (values, onSubmitProps) => {
    onSubmitProps.setSubmitting(true);
    Swal.fire({
      title: `${t("on_add")}`,
      showCancelButton: true,
      confirmButtonText: `${t("add_roll")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await HTTP.post(`/qrolls/create`, values)
          .then((response) => {
            getData();
            Swal.fire({
              position: "top",
              icon: "success",
              title: `${t("added")}`,
              showConfirmButton: false,
              timer: 3000,
            });
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm();
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      } else {
        onSubmitProps.setSubmitting(false);
      }
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleAdd}
      validateOnMount
    >
      {(formik) => (
        <Form className="w-100 mb-5">
          <h4 className="mb-3">{t("add_roll")}</h4>
          <div className="addRollForm">
            <Row>
              <Col md={6}>
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.code}
                  error={formik.errors.code}
                  touched={formik.touched.code}
                  type="text"
                  name="code"
                  label={t("code")}
                />
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.thikness}
                  error={formik.errors.thikness}
                  touched={formik.touched.thikness}
                  type="number"
                  name="thikness"
                  label={t("thikness")}
                  step={0.01}
                />
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.width}
                  error={formik.errors.width}
                  touched={formik.touched.width}
                  type="number"
                  name="width"
                  label={t("width")}
                />
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.netWeight}
                  error={formik.errors.netWeight}
                  touched={formik.touched.netWeight}
                  type="number"
                  name="netWeight"
                  label={t("net_weight")}
                  step={0.01}
                />
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.grossWeight}
                  error={formik.errors.grossWeight}
                  touched={formik.touched.grossWeight}
                  type="number"
                  name="grossWeight"
                  label={t("gross_weight")}
                />
                <FormikController
                  control="input"
                  formik={formik}
                  value={formik.values.length}
                  error={formik.errors.length}
                  touched={formik.touched.length}
                  type="number"
                  name="length"
                  label={t("length")}
                  step={0.01}
                />
              </Col>
              <Col md={6}>
                <FormikController
                  control="select"
                  formik={formik}
                  value={formik.values.type}
                  error={formik.errors.type}
                  touched={formik.touched.type}
                  options={types}
                  name="type"
                  label={t("type")}
                />
                <FormikController
                  control="select"
                  formik={formik}
                  value={formik.values.rollQuality}
                  error={formik.errors.rollQuality}
                  touched={formik.touched.rollQuality}
                  options={rollQ}
                  name="rollQuality"
                  label={t("rollQuality")}
                />
                <FormikController
                  control="select"
                  formik={formik}
                  value={formik.values.madeIn}
                  error={formik.errors.madeIn}
                  touched={formik.touched.madeIn}
                  options={countries}
                  name="madeIn"
                  label={t("made_in")}
                />
                <FormikController
                  control="textarea"
                  formik={formik}
                  value={formik.values.addedComment}
                  error={formik.errors.addedComment}
                  touched={formik.touched.addedComment}
                  name="addedComment"
                  label={t("comment")}
                />
                {role == "Admin" || role == "Accountant" ? (
                  <DatePicker
                    placeholder={t("date")}
                    className="my-1 my-md-2 w-100"
                    size={"large"}
                    onChange={(date, dateString) => {
                      formik.setFieldValue("createdAt", dateString);
                    }}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <div className="w-100 d-flex justify-content-end">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {t("add_roll")}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddRollForm;

import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAuth } from "../auth";
import { useTranslation } from "react-i18next";
import { HTTP } from "../../HTTPS";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Cookies from "js-cookie";
import { Button, Chip } from "@mui/material";
import { AiFillPrinter } from "react-icons/ai";
import logo from "../../assets/imgs/logo.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Space, Spin, Switch } from "antd";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportAll = () => {
  const componentRef = useRef();
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const { t } = useTranslation();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState();
  const [show, setShow] = useState({
    piece: true,
    weight: true,
    packet: true,
  });
  const onChange = (e) => {
    setShow({
      ...show,
      [e.target.name]: e.target.checked,
    });
  };
  const stores = [
    { key: "fs", value: `${t("f_store")}` },
    { key: "qs", value: `${t("bp_store")}` },
    { key: "bs", value: `${t("qb_store")}` },
    { key: "pl", value: `${t("plets")}` },
    { key: "qsr", value: `${t("qb_rolls")}` },
    { key: "fsr", value: `${t("bp_rolls")}` },
  ];
  const [selectedSotres, setSelectedStores] = useState([
    "fs",
    "qs",
    "bs",
    "pl",
    "fsr",
    "qsr",
  ]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStores(value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getData = async () => {
    setLoading(true);
    await HTTP.get(`/qbstore/reportall?_need=${selectedSotres}`)
      .then((response) => {
        setAllData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getData();
  }, [selectedSotres]);
  var fst = true;
  return (
    <>
      <div className="allreport-header d-flex justify-content-between align-items-cenetr">
        <div>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => {
              handlePrint();
            }}
          >
            <span className={currentLanguageCode == "en" ? "en-f" : "kr-f"}>
              <AiFillPrinter /> {t("print")}
            </span>
          </Button>
        </div>
        <div>
          <Form.Check
            className="d-inline-block"
            type="switch"
            id="custom-switch"
            name="piece"
            label={t("piece")}
            checked={show.piece}
            onChange={(e) => onChange(e)}
          />
          <Form.Check
            className="d-inline-block mx-4"
            type="switch"
            id="custom-switch"
            name="weight"
            label={t("weight")}
            checked={show.weight}
            onChange={(e) => onChange(e)}
          />
          <Form.Check
            className="d-inline-block"
            type="switch"
            id="custom-switch"
            name="packet"
            label={t("packet")}
            checked={show.packet}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div>
          <FormControl sx={{ width: 400 }}>
            <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              size="small"
              value={selectedSotres}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) =>
                selected.map((st, key) => (
                  <Chip
                    key={st}
                    label={stores?.find((e) => e.key === st).value}
                  />
                ))
              }
              MenuProps={MenuProps}
            >
              {stores.map((str) => (
                <MenuItem key={str.key} value={str.key}>
                  <Checkbox checked={selectedSotres.includes(str.key)} />
                  <ListItemText primary={str.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </div>
      ) : (
        <Row className="allreport mt-4">
          <div
            ref={componentRef}
            style={{
              width: "100%",
              height: "100%",
            }}
            className={currentLanguageCode == "en" ? "ltr" : "rtl"}
          >
            <Container className="report-border px-0">
              <Row className="mx-auto">
                <Col
                  xs={{ span: 5, order: currentLanguageCode == "en" ? 1 : 2 }}
                  className={`d-flex align-items-center justify-content-${
                    currentLanguageCode == "en" ? "end" : "start"
                  }`}
                >
                  <img src={logo} className="img-fluid" alt="" />
                </Col>
                <Col
                  xs={{ span: 7, order: currentLanguageCode == "en" ? 2 : 1 }}
                  className={`d-flex align-items-center justify-content-${
                    currentLanguageCode == "en" ? "start" : "end"
                  }`}
                >
                  <div className="header-text">
                    <h1 className="m-0">معمل بلاك بایب</h1>
                    <p className="m-0 mt-2 px-3 bg-danger">
                      لانتاج المقاطع الحدیدية<span className="px-3">l</span>
                      المغلون و الاسود
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mx-auto overflow-hidden mt-4">
                {selectedSotres.map((strs) => {
                  if (
                    (strs == "fs" || strs == "qs" || strs == "bs") &&
                    fst == true
                  ) {
                    fst = false;
                    return (
                      <>
                        <h5 className="mb-1 p-0 text-start">
                          {t("allproducts")}
                        </h5>
                        <Table responsive bordered className="text-center ">
                          <thead>
                            <tr className="printTableHeaderSize">
                              <th className="bg-danger text-white">
                                {t("name")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("type")}
                              </th>
                              <th
                                className={`bg-danger text-white ${
                                  show.piece ? "" : "d-none"
                                }`}
                              >
                                {t("piece")}
                              </th>
                              <th
                                className={`bg-danger text-white ${
                                  show.weight ? "" : "d-none"
                                }`}
                              >
                                {t("weight")}
                              </th>
                              <th
                                className={`bg-danger text-white ${
                                  show.packet ? "" : "d-none"
                                }`}
                              >
                                {t("packets")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("packet_weight")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("packet_qnt")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("piece_weight")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("piece_in_ton")}
                              </th>
                              <th className="bg-danger text-white">
                                {t("from")}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="printTablebodySize">
                            {allData?.products?.rows.map((x, index) => {
                              let a = allData.products.count.filter((i) => {
                                return (
                                  i.line === x.line &&
                                  i.name === x.list.name &&
                                  i.quality === x.quality &&
                                  i.type === x.list.type
                                );
                              });
                              return (
                                <tr key={index}>
                                  <td>{x.list.name}</td>
                                  <td>{x.list.type}</td>
                                  <td
                                    className={`${show.piece ? "" : "d-none"}`}
                                  >
                                    {x.packetQnt}
                                  </td>
                                  <td
                                    className={`${show.weight ? "" : "d-none"}`}
                                  >
                                    {x.packetWeight}
                                  </td>
                                  <td
                                    className={`${show.packet ? "" : "d-none"}`}
                                  >
                                    {a[0].count}
                                  </td>
                                  <td>
                                    {x.list.standartPacketWeight} {t("kg")}
                                  </td>
                                  <td>{x.list.standartPacketQnt}</td>
                                  <td>
                                    {x.list.standartPieceWeight} {t("kg")}
                                  </td>
                                  <td>
                                    {(
                                      1000 / Number(x.list.standartPieceWeight)
                                    ).toFixed(0)}
                                  </td>
                                  <td>{x.madeIn}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    );
                  }
                  if (strs == "pl") {
                    return (
                      <>
                        {allData?.pl?.rows?.length ? (
                          <>
                            <h5 className="mb-1 p-0 text-start">
                              {t("plets")}
                            </h5>
                            <Table responsive bordered className="text-center">
                              <thead>
                                <tr>
                                  <th className="bg-danger text-white">
                                    {t("qnt")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("type")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("thikness")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("width")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("quality")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("made_in")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("length")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("weight")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {allData.pl.rows.map((x, index) => {
                                  let a = allData.pl.count.filter((i) => {
                                    return (
                                      i.thikness === x.qroll.thikness &&
                                      i.width === x.qroll.width &&
                                      i.type === x.qroll.type &&
                                      i.rollQuality === x.qroll.rollQuality &&
                                      i.len === x.len
                                    );
                                  });
                                  return (
                                    <tr key={index}>
                                      <td>{a[0].count}</td>
                                      <td>{x.qroll.type}</td>
                                      <td>{x.qroll.thikness}</td>
                                      <td>{x.qroll.width}</td>
                                      <td>{x.qroll.rollQuality}</td>
                                      <td>{x.qroll.madeIn}</td>
                                      <td>
                                        {x.len} {t("m")}
                                      </td>
                                      <td>
                                        {x.weight} {t("kg")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </>
                        ) : null}
                      </>
                    );
                  }
                  if (strs == "qsr") {
                    return (
                      <>
                        {allData?.qbrol?.rows?.length ? (
                          <>
                            <h5 className="mb-1 p-0 text-start">
                              {t("qb_rolls")}
                            </h5>
                            <Table responsive bordered className="text-center">
                              <thead>
                                <tr>
                                  <th className="bg-danger text-white">
                                    {t("qnt")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("type")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("thikness")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("width")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("quality")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("made_in")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("total_weight")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {allData.qbrol.rows.map((x, index) => {
                                  let a = allData.qbrol.count.filter((i) => {
                                    return (
                                      i.thikness === x.thikness &&
                                      i.width === x.width &&
                                      i.type === x.type &&
                                      i.rollQuality === x.rollQuality
                                    );
                                  });
                                  return (
                                    <tr key={index}>
                                      <td>{a[0].count}</td>
                                      <td>{x.type}</td>
                                      <td>{x.thikness}</td>
                                      <td>{x.width}</td>
                                      <td>{x.rollQuality}</td>
                                      <td>{x.madeIn}</td>
                                      <td>
                                        {x.total_weight} {t("kg")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </>
                        ) : null}
                      </>
                    );
                  }
                  if (strs == "fsr") {
                    return (
                      <>
                        {allData?.bprol?.rows?.length ? (
                          <>
                            <h5 className="mb-1 p-0 text-start">
                              {t("bp_rolls")}
                            </h5>
                            <Table responsive bordered className="text-center">
                              <thead>
                                <tr>
                                  <th className="bg-danger text-white">
                                    {t("qnt")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("type")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("thikness")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("width")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("quality")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("made_in")}
                                  </th>
                                  <th className="bg-danger text-white">
                                    {t("total_weight")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {allData.bprol.rows.map((x, index) => {
                                  let a = allData.bprol.count.filter((i) => {
                                    return (
                                      i.thikness === x.thikness &&
                                      i.width === x.width &&
                                      i.type === x.type &&
                                      i.rollQuality === x.rollQuality
                                    );
                                  });
                                  return (
                                    <tr key={index}>
                                      <td>{a[0].count}</td>
                                      <td>{x.type}</td>
                                      <td>{x.thikness}</td>
                                      <td>{x.width}</td>
                                      <td>{x.rollQuality}</td>
                                      <td>{x.madeIn}</td>
                                      <td>
                                        {x.total_weight} {t("kg")}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </>
                        ) : null}
                      </>
                    );
                  }
                })}
              </Row>
            </Container>
          </div>
        </Row>
      )}
    </>
  );
};

export default ReportAll;

import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { HTTP } from "../../../HTTPS";
import FormikController from "../../Forms/FormikController";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { useAuth } from "../../auth";
import { Button, DatePicker } from "antd";

const Produce = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");

  const [day, setDay] = useState(moment().format("DD"));
  const [month, setMonth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YY"));

  const [selectedListDatas, setSelectedListDatas] = useState();
  const [lists, setLists] = useState([]);
  const [codeLength, setCodeLength] = useState("");
  const [line, setLine] = useState("");

  const quality = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];
  const lines = [
    { key: "101A", value: "101A" },
    { key: "76B", value: "76B" },
    { key: "76C", value: "76C" },
    { key: "51D", value: "51D" },
  ];

  const initialValues = {
    line: "",
    packetQnt: "",
    packetWeight: "",
    quality: "1",
    productCode: "",
    from: "Factory",
    madeIn: "BlackPipe",
    p_comment: "",
    producer: localStorage.getItem("username"),
    listId: "",
    createdAt: "",
  };
  const validationSchema = Yup.object({
    packetQnt: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    packetWeight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    quality: Yup.string().required("Required"),
    p_comment: Yup.string().required("Required"),
  });
  const getLists = async () => {
    await HTTP.get(`/lists/all?_search=&_from=Factor`)
      .then((response) => {
        setLists(response.data.rows);
      })
      .catch((error) => {
        setLists([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const getCodeLength = async () => {
    if (!line) {
    } else {
      await HTTP.get(
        `/produce/today?_date=${moment().format("Y-MM-DD")}&_line=${line}`
      )
        .then((response) => {
          if (response.data.count > 0) {
            let code = Number(response.data.rows[0].productCode.slice(-3));
            if (Number(code < 1)) {
              setCodeLength(`001`);
            } else if (Number(code) < 10) {
              setCodeLength(`00${code + 1}`);
            } else if (Number(code) >= 10 && Number(code) < 100) {
              setCodeLength(`0${code + 1}`);
            }
          } else {
            setCodeLength(`001`);
          }
        })
        .catch((error) => {
          setCodeLength("");
          if (error.response.status == 401) {
            auth.logout();
          } else if (error.response.status === 404) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: `${t("Data_Not_Found")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          } else alert("Somthing is wrong, Please try again later");
        });
    }
  };

  const handleAdd = (values, onSubmitProps) => {
    Swal.fire({
      title: `${t("on_produce")}`,
      showCancelButton: true,
      confirmButtonText: `${t("add_product")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      onSubmitProps.setSubmitting(true);
      if (result.isConfirmed) {
        await HTTP.post(`/produce/create`, values)
          .then((response) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("added")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            getCodeLength();
            window.location.reload(false);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              auth.logout();
            } else {
              const message = error.response.data.error.message;
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      }
    });
  };

  useEffect(() => {
    getLists();
  }, []);
  useEffect(() => {
    getCodeLength();
  }, []);
  return (
    <div className="bg-sh">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAdd}
        validateOnMount
      >
        {(formik) => (
          <>
            <Row className="mb-3  addUserForm">
              <h4 className="mb-3">{t("produce_new")}</h4>
              <Form className="w-100 mb-3">
                <Row>
                  <Col md={3}>
                    <FormControl className="w-100 mb-3 ">
                      <InputLabel id="demo-simple-select-helper-label">
                        {t("select_line")}
                      </InputLabel>
                      <Select
                        size="small"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label={t("select_line")}
                        value={line}
                        onChange={async (e) => {
                          // \\\\\\\\\\\\\\\\\\\\\\\\\\\
                          await HTTP.get(
                            `/produce/today?_date=${moment().format(
                              "Y-MM-DD"
                            )}&_line=${e.target.value}`
                          )
                            .then((response) => {
                              if (response.data.count > 0) {
                                let code = Number(
                                  response.data.rows[0].productCode.slice(-3)
                                );
                                if (Number(code < 1)) {
                                  setCodeLength(`001`);
                                  formik.setFieldValue(
                                    "productCode",
                                    `${day}${month}${year[1]}${
                                      selectedListDatas?.type
                                    }${e.target.value}${`001`}`
                                  );
                                } else if (Number(code) < 10) {
                                  setCodeLength(`00${code + 1}`);
                                  formik.setFieldValue(
                                    "productCode",
                                    `${day}${month}${year[1]}${
                                      selectedListDatas?.type
                                    }${e.target.value}${`00${code + 1}`}`
                                  );
                                } else if (
                                  Number(code) >= 10 &&
                                  Number(code) < 100
                                ) {
                                  setCodeLength(`0${code + 1}`);
                                  formik.setFieldValue(
                                    "productCode",
                                    `${day}${month}${year[1]}${
                                      selectedListDatas?.type
                                    }${e.target.value}${`0${code + 1}`}`
                                  );
                                }
                              } else {
                                setCodeLength(`001`);
                                formik.setFieldValue(
                                  "productCode",
                                  `${day}${month}${year[1]}${
                                    selectedListDatas?.type
                                  }${e.target.value}${`001`}`
                                );
                              }
                            })
                            .catch((error) => {
                              setCodeLength("");
                              if (error.response.status == 401) {
                                auth.logout();
                              } else if (error.response.status === 404) {
                                Swal.fire({
                                  position: "top-end",
                                  icon: "error",
                                  title: `${t("Data_Not_Found")}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                              } else
                                alert(
                                  "Somthing is wrong, Please try again later"
                                );
                            });

                          // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
                          formik.setFieldValue("line", e.target.value);
                          setLine(e.target.value);
                        }}
                      >
                        {lines.map((option) => {
                          return (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Autocomplete
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      size="small"
                      id="combo-box-demo"
                      name="listId"
                      label={t("select_list")}
                      disabled={!line}
                      options={lists}
                      getOptionLabel={(option) =>
                        `${option.name}*${option.type}`
                      }
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          formik.setFieldValue("listId", "");
                          formik.setFieldValue("productCode", ``);
                          setSelectedListDatas("");
                        } else {
                          setSelectedListDatas(newValue);
                          formik.setFieldValue(
                            "productCode",
                            `${day}${month}${year[1]}${newValue.type}${line}${codeLength}`
                          );
                          formik.setFieldValue("listId", newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<em>{t("select_list")}</em>}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="p-3 ">
                  <Col md={6}>
                    <span className="l-text align-top">{t("type")}: </span>
                    {selectedListDatas && (
                      <span className="h6 r-text">
                        {selectedListDatas.type}
                      </span>
                    )}
                    <br />
                    <span className="l-text align-top">
                      {t("s_packet_qnt")}:{" "}
                    </span>
                    {selectedListDatas && (
                      <span className="h6 r-text">
                        {selectedListDatas.standartPacketQnt}
                      </span>
                    )}
                    <br />
                  </Col>
                  <Col md={6}>
                    <span className="l-text align-top">
                      {t("s_packet_w")}:{" "}
                    </span>
                    {selectedListDatas && (
                      <span className="h6 r-text">
                        {selectedListDatas.standartPacketWeight}
                      </span>
                    )}
                    <br />
                    <span className="l-text align-top">{t("s_piece_w")}: </span>
                    {selectedListDatas && (
                      <span className="h6 r-text">
                        {selectedListDatas.standartPieceWeight}
                      </span>
                    )}
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col md={3} className="mb-3">
                    {role == "Admin" || role == "Accountant" ? (
                      <DatePicker
                        placeholder={t("date")}
                        className="my-1 my-md-2"
                        size={"large"}
                        onChange={async (date, dateString) => {
                          formik.setFieldValue("createdAt", dateString);
                          await HTTP.get(
                            `/produce/today?_date=${dateString}&_line=${line}`
                          )
                            .then((response) => {
                              if (response.data.count > 0) {
                                let code = Number(
                                  response.data.rows[0].productCode.slice(-3)
                                );
                                if (Number(code < 1)) {
                                  formik.setFieldValue(
                                    "productCode",
                                    `${moment(dateString).format("DD")}${moment(
                                      dateString
                                    ).format("MM")}${
                                      moment(dateString).format("YY")[1]
                                    }${selectedListDatas?.type}${line}${`001`}`
                                  );
                                } else if (Number(code) < 10) {
                                  formik.setFieldValue(
                                    "productCode",
                                    `${moment(dateString).format("DD")}${moment(
                                      dateString
                                    ).format("MM")}${
                                      moment(dateString).format("YY")[1]
                                    }${selectedListDatas?.type}${line}${`00${
                                      code + 1
                                    }`}`
                                  );
                                } else if (
                                  Number(code) >= 10 &&
                                  Number(code) < 100
                                ) {
                                  formik.setFieldValue(
                                    "productCode",
                                    `${moment(dateString).format("DD")}${moment(
                                      dateString
                                    ).format("MM")}${
                                      moment(dateString).format("YY")[1]
                                    }${selectedListDatas?.type}${line}${`0${
                                      code + 1
                                    }`}`
                                  );
                                }
                              } else {
                                formik.setFieldValue(
                                  "productCode",
                                  `${moment(dateString).format("DD")}${moment(
                                    dateString
                                  ).format("MM")}${
                                    moment(dateString).format("YY")[1]
                                  }${selectedListDatas?.type}${line}${`001`}`
                                );
                              }
                            })
                            .catch((error) => {
                              setCodeLength("");
                              if (error.response.status == 401) {
                                auth.logout();
                              } else if (error.response.status === 404) {
                                Swal.fire({
                                  position: "top-end",
                                  icon: "error",
                                  title: `${t("Data_Not_Found")}`,
                                  showConfirmButton: false,
                                  timer: 1500,
                                });
                              } else
                                alert(
                                  "Somthing is wrong, Please try again later"
                                );
                            });
                          if (dateString) {
                            setDay(moment(dateString).format("DD"));
                            setMonth(moment(dateString).format("MM"));
                            setYear(moment(dateString).format("YY"));
                          } else {
                            formik.setFieldValue(
                              "productCode",
                              `${moment().format("DD")}${moment().format(
                                "MM"
                              )}${moment().format("YY")[1]}${
                                selectedListDatas?.type
                              }${line}${codeLength}`
                            );
                            setDay(moment().format("DD"));
                            setMonth(moment().format("MM"));
                            setYear(moment().format("YY"));
                          }
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row className="produce-form">
                  <div className=" d-flex flex-wrap">
                    <div
                      className="flex-grow-1"
                      style={{
                        padding: "0 5px",
                      }}
                    >
                      <FormikController
                        control="input"
                        formik={formik}
                        value={formik.values.packetQnt}
                        error={formik.errors.packetQnt}
                        touched={formik.touched.packetQnt}
                        type="number"
                        name="packetQnt"
                        label={t("packet_qnt")}
                        disabled={!selectedListDatas}
                        max={selectedListDatas?.standartPacketQnt}
                      />
                    </div>
                    <div
                      className="flex-grow-1"
                      style={{
                        padding: "0 5px",
                      }}
                    >
                      <FormikController
                        control="input"
                        formik={formik}
                        value={formik.values.packetWeight}
                        error={formik.errors.packetWeight}
                        touched={formik.touched.packetWeight}
                        type="number"
                        name="packetWeight"
                        label={t("packet_w")}
                        disabled={!selectedListDatas}
                        step={0.01}
                      />
                    </div>
                    <div
                      className="flex-grow-1"
                      style={{
                        minWidth: "100px",
                        padding: "0 5px",
                      }}
                    >
                      <FormikController
                        control="select"
                        formik={formik}
                        value={formik.values.quality}
                        error={formik.errors.quality}
                        touched={formik.touched.quality}
                        options={quality}
                        name="quality"
                        label={t("quality")}
                        disabled={!selectedListDatas}
                      />
                    </div>
                    <div
                      className="flex-grow-1"
                      style={{
                        padding: "0 5px",
                      }}
                    >
                      <FormikController
                        control="input"
                        formik={formik}
                        value={
                          !selectedListDatas ? "" : formik.values.productCode
                        }
                        type="text"
                        name="productCode"
                        label={t("code")}
                        disabled
                      />
                    </div>
                    <div
                      className="flex-grow-1"
                      style={{
                        padding: "0 5px",
                      }}
                    >
                      <FormikController
                        control="textarea"
                        formik={formik}
                        value={formik.values.p_comment}
                        error={formik.errors.p_comment}
                        touched={formik.touched.p_comment}
                        name="p_comment"
                        label={t("comment")}
                        disabled={!selectedListDatas}
                      />
                    </div>
                  </div>
                </Row>
                <Button
                  className="px-5 mt-3"
                  type="primary"
                  htmlType="submit"
                  variant="outline-primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {t("produce")}
                </Button>
              </Form>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Produce;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    blackpipe: "بلاك پایپ",
    dashboard: "سەرەکی ",
    users: "بەکارهێنەرەکان",
    login: "چوونەژوورەوە",
    logout: "دەرچوون",
    roll: "ڕۆل",
    rolls: "ڕۆلەکان",
    store: "کۆگا",
    lists: "لیستی بەرهەمەکان",
    activity: "چالاکی",
    slitter: "شراحە",
    pieces: "پارچەکان",
    piece: "پارچە",
    wastes: "پاشماوەکان",
    tarqeq: "تەرقیق",
    tambur: "تەمبور",
    sent_to_tambur: "نێردراو بۆ تەمبور",
    list_name: "بەرهەم",
    produce: "بەرهەمهێنان",
    produce_new: "بەرهەمهێنان",
    f_store: "کۆگای كارگە",
    bp_store: "کۆگای بلاك پایپ",
    qb_store: "کۆگای قندیل بلباس",
    transfers: "بەرهەمە نێردراوەکان",
    slitteds: "ڕۆڵە بڕاوەکان",
    deleted_slitteds: "ڕۆلی بڕدراوی سڕاوە",
    deleted_pieces: "شریحەی سڕاوە",
    wastings: "پاشماوەکان",
    removed_from_tambur: "سڕاوە لە تەمبور",
    spare_lists: "لیستی یەدەک",
    spare_parts: "پارچە یەدەکەکان",
    deleted_rolls: "ڕۆلی سڕاوە",
    cut_roll: "پارچەکردنی ڕۆڵ:",
    plets: "پلێتەکان",
    sold_plets: "پلێتە فرۆشراوەکان",
    // \\\\\\\
    kg: "کگم",
    mm: "ملم",
    m: "م",
    is_cut:"براوە",
    number_of_plets:"ژمارەی پلێتەکان",
    piece_in_ton:"دانە لە تەن",
    all_report:"راپۆرتی هەموو بەرهەمەکان",
    bp_rolls:"رۆلی بلاك بایب",
    qb_rolls:"رۆلی قندیل بلباس",
    packets:"پاکەت",
    whole_roll: "برینی تەواوی رۆڵەکە",
    how_many_groups: "برین بۆ X گروپ",
    number_of_pieces: "ژمارەی پلێت",
    length: "درێژی",
    piece_weight: "کێشی دانە",
    note: "تێبینی",
    report: "راپۆرت",
    pr_weight: "کێش",
    product_name: "ناوی بەرهەم",
    product_report: "راپۆرتی بەرهەمهێنان",
    s_packet_qnt: "بڕی پێوانەیی لە پاکەت",
    spq: "بڕ لە پاکەت",
    s_packet_w: "کێشی پێوانەیی پاکەت",
    s_piece_w: "کێشی پێوانەیی ١ دانە",
    select_list: "لیستێک هەڵبژێرە",
    select_line: "‌هێلێک هەڵبژێرە",
    type: "جۆر",
    qnt: "بڕ",
    weight: "کێش",
    code: "کۆد",
    made_in: "دروستکراوی",
    from: "لە لایەن",
    date: "ڕێککەوت",
    line: "‌هێڵ",
    quality: "کوالێتی",
    car_no: "ژمارەی ئۆتۆمبێل",
    car_type: "جۆری ئۆتۆمبێل",
    sell: "فرۆشتن",
    sell_to: "فرۆشتن بە",
    sold_by: "فرۆشیار",
    sold: "فرۆشرا",
    driverName: "ناوی شوفێر",
    driverPhone: "ژ. مۆبایلی شوفێر",
    pipes: "بۆری",
    total_weight: "کێشی گشتی",
    total_qnt: "بڕی گشتی",
    packet_qnt: "بڕی پاکەت",
    packet_weight: "کێشی پاکەت",
    thikness: "ئەستووری",
    width: "پانی",
    net_weight: "کێشی تەواو",
    gross_weight: "کێشی گشتی",
    tLength: "درێژی تیۆری",
    aLength: "درێژی تەواو",
    rollQuality: "کوالیتی ڕۆڵ",
    comment: "تێبینی",
    added_by: "زیادکراوە لە لایەن",
    time: "کاژێر",
    remains: "ماوە",
    devided_into: "دابەشبووە بۆ",
    devid_into: "دابەشبێ بۆ",
    real_width: "پانی تەواو",
    slitted_by: "بڕاوە لە لایەن",
    roll_code: "کۆدی ڕۆڵ",
    tarqeq_length: "درێژی دوای تەرقیق",
    tarqeq_thikness: "ئەستووری دوای تەرقیق",
    deleted_comment: "هۆکاری سڕانەوە",
    deleted_date: "ڕێککەوتی سڕینەوەی",
    deleted_by: "سڕاوەتەوە لە لایەن",
    upload_image: "زیادکردنی وێنە",
    tambured_by: "تەمبور کراوە لە لایەن",
    role: "ئەرک",
    username: "ناوی بەکارهێنەر",
    name: "ناو",
    phone: "ژ. مۆبایل",
    password: "وشەی تێپەڕ",
    reports: "ڕاپۆرتەکان",
    Report_of: "ڕاپۆرتی",
    technician: "تەکنیک کار",
    from_time: "کاتی دەستپێک",
    to_time: "کۆتایی هاتن",
    speed: "خێرایی",
    report_form: "فۆرمی ڕاپۆرت",
    report_list: "جۆری ڕاپۆرت",
    year: "ساڵ",
    none: "هیچ کام لەمانە",
    all: "هەموو لەمانە",
    print: "چاپکردن",
    used_spares: "پارچەی یەدەگی بەکارهاتوو",
    usedBy: "بەکارهاتووە لەلایەن",
    gaveTo: "دراوە بە",
    reset_password: "نوێکردنەوەی وشەی تێپەر",
    newPassword: "وشەی تێپەری نوێ",
    day_series: "ڕیزبەندی ڕۆل لە ڕۆژ",
    piece_series: "ڕیزبەندی شریحە",
    actual_weight: "کێشی ڕاست",
    total_real_weight: "کێشی تەواوی گشتی",
    total: "کۆی گشتی",
    // \\\\\\\
    reset: "نوێکردنەوە",
    reseted: "نوێکرایەوە",
    use: "بەکارهێنان",
    used: "بەکارهات",
    send: "ناردن",
    sent: "نێردرا",
    slit: "بڕین",
    slited: "بڕدرا",
    delete: "سڕینەوە",
    deleted: "سڕایەوە",
    edit: "دەستکاری",
    update: "چاککردن",
    updated: "چاککرا",
    added: "زیاد کرا",
    cancel: "گەڕانەوە",
    search: "گەڕان",
    transfer: "ناردن",
    transferred: "نێردرا",
    transfer_to: "ناردن بۆ",
    transferred_to: "نێردراوە بۆ",
    transferred_by: "نێردەر",
    return: "گەرانەوە",
    returned: "ەرایەوە",
    cut: "پارچەکردن",
    add_roll: "زیادکردنی ڕۆل",
    add_list: "تۆمارکردنی بەرهەم",
    add_product: "زیادکردنی بەرهەم",
    add_wasting: "زیادکردنی پاشماوە",
    add_piece: "زیادکردنی شریحە ",
    add_spare_list: "زیاد کردنی لیستی پارچە یەدەک",
    add_spare: "زیاد کردنی یەدەگ",
    add_user: "زیادکردنی بەکارهێنەر",
    add_plet: "زیادکردنی پلێت",
    // \\\\\\\\\\\\\\\\\\
    on_add_product: "ئایا دڵنیای لە زیادکردنی بەرهەم؟",
    on_transfer: "ئایا دڵنیای لە ناردن؟",
    on_produce: "ئایا دڵنیای لە بەرهەمهێنان",
    on_add: "ئایا دڵنیای لە زیادکردن؟",
    on_update: "ئایا دڵنیای لە چاککردنی؟",
    on_return: "ئایا دڵنیای لە گەرانەوە؟",
    on_tambur: "ئایا دڵنیای لە ناردنی بۆ تەمبور؟",
    on_slit: "ئایا دڵنیای لە بڕینی",
    on_tarqeq: "ئایا دڵنیای لە تەرقیق کردن؟",
    on_delete: "ئایا دڵنیای لە سڕینەوەی؟",
    on_delete_per: "ئایا دڵنیای لە سڕینەوەی بە تەواوەتی؟",
    on_use: "ئایا دڵنیای لە بەکارهێنانی؟",
    on_reset: "ئایا دڵنییای لە نوێکردنەوەی وشەی تێپەر؟",
    on_cut: "ئایا دڵنیای لە پارچەکردنی ئەم ڕۆڵە؟",
    on_sell: "ئایا دڵنیای لە فرۆشتن؟",
    // \\\\\\\\\\\\\
    productions: "بەرهەمەکان",
    qandelblbas: "قندیل بلباس",
    empty_store: "هیچ بەرهەمێک لەم کۆگایە بەردەست نییە",
    not_enough: "بڕی داواکراو بەردەست نییە",
    all_sold: "هەموو فرۆشراوەکان",
    size: "قەبارە",
    seller: "فرۆشیار",
    Data_Not_Found: "هیچ داتایەک نەدۆزرایەوە",
    page_not_found: "لاپەرە نەدۆزرایەوە",
    allproducts: "هەموو بەرهەمەکان",
    for_order: "بۆ داواکردن",
  },
};

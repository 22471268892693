import React from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { HTTP } from "../../../HTTPS";
import { useAuth } from "../../auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Button, DatePicker, Input, Select, Space, Spin, Table } from "antd";
import EachProuct from "./EachProduct";
import SelectedForm from "./SelectedForm";
import NotSelectedForm from "./NotSelectedForm";
const { Search } = Input;

const BpStore = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [showedQuality, setShowedQuality] = useState("1");
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [openSendFormModal, setOpenSendFormModal] = useState(false);
  const handleSendModalClose = () => {
    setShowedQuality(showedQuality);
    getBpStore();
    setOpenSendFormModal(false);
  };
  const quality = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];
  const productionsColumns = [
    {
      title: `${t("list_name")}`,
      key: "name",
      width: 75,
      render: (record) => <>{record.production.list.name}</>,
    },
    {
      title: `${t("total_qnt")}`,
      key: "total_qnt",
      width: 75,
      render: (record) => (
        <>
          {record.production.packetQnt} {t("pipes")}
        </>
      ),
    },
    {
      title: `${t("total_weight")}`,
      key: "total_weight",
      width: 75,
      render: (record) => (
        <>
          {record.production.packetWeight} {t("kg")}
        </>
      ),
    },
    {
      title: `${t("quality")}`,
      key: "quality",
      width: 35,
      render: (record) => <>{record.production.quality}</>,
    },
    {
      title: `${t("type")}`,
      key: "type",
      width: 35,
      render: (record) => <>{record.production.list.type}</>,
    },
    {
      title: `${t("line")}`,
      key: "line",
      width: 35,
      render: (record) => <>{record.production.line}</>,
    },
    {
      title: `${t("s_packet_qnt")}`,
      key: "standartPacketQnt",
      width: 75,
      render: (record) => <>{record.production.list.standartPacketQnt}</>,
    },
    {
      title: `${t("s_packet_w")}`,
      key: "standartPacketWeight",
      width: 75,
      render: (record) => <>{record.production.list.standartPacketWeight}</>,
    },
  ];

  const handleAddSelectedRows = (id) => {
    var include = false;
    for (var i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i] == id) {
        include = true;
      }
    }
    if (!include) {
      setSelectedProducts([...selectedProducts, id]);
    }
  };
  const handleRemoveSelectedRows = (id) => {
    var include = false;
    for (var i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i] == id) {
        include = true;
      }
    }
    if (include) {
      for (var j = 0; j < selectedProducts.length; j++) {
        if (selectedProducts[j] == id) {
          selectedProducts.splice(j, 1);
        }
      }
    }
  };

  const getBpStore = async () => {
    setProducts([]);
    setLoading(true);
    await HTTP.get(
      `/bpstore/grouped?_quality=${showedQuality}&_search=${search}&_date=${date}`
    )
      .then((response) => {
        setProducts(response.data.rows);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getBpStore();
  }, [showedQuality, search, date]);

  return (
    <>
      <Row className="mt-5">
        <Col md={2}>
          <h4 className="mb-3">{t("bp_store")} </h4>
        </Col>
        <Col md={2}>
          <span>{t("quality")} </span>
          <Select
            value={showedQuality}
            options={quality}
            onChange={(e) => {
              setShowedQuality(e);
            }}
          />
        </Col>
        <Col md={2}>
          {role == "Admin" ||
          role == "Accountant" ||
          role == "Store"  ? (
            <Button
              onClick={() => {
                setOpenSendFormModal(true);
              }}
              disabled={!products.length}
              type="primary"
              className="px-5 my-1 my-md-2"
              ghost
            >
              {t("transfer")}
            </Button>
          ) : (
            <></>
          )}
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={productionsColumns}
        defaultExpandAllRows={true}
        size="small"
        className="tbs bg"
        id="rollsTable"
        rowKey="id"
        pagination={false}
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <EachProuct
              record={record}
              handleAddSelectedRows={handleAddSelectedRows}
              handleRemoveSelectedRows={handleRemoveSelectedRows}
              getBpStore={getBpStore}
            />
          ),
        }}
        dataSource={products}
      />
      {/* Transfer MODAL */}
      <Modal
        show={openSendFormModal}
        onHide={handleSendModalClose}
        fullscreen
        aria-labelledby="update-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="update-modal-title">{t("transfer")}</Modal.Title>
        </Modal.Header>
        <Container
          className="pb-5 pb-md-0"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {selectedProducts.length ? (
            <SelectedForm
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              handleClose={handleSendModalClose}
              getBpStore={getBpStore}
            />
          ) : (
            <NotSelectedForm
              setSelectedProducts={setSelectedProducts}
              handleClose={handleSendModalClose}
              getBpStore={getBpStore}
            />
          )}
        </Container>
      </Modal>
    </>
  );
};

export default BpStore;

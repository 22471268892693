import React from "react";
import { HTTP } from "../../../HTTPS";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import FormikController from "../../Forms/FormikController";
import { Button } from "antd";

const BpAddProduct = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [lists, setLists] = useState([]);
  const [selectedListDatas, setSelectedListDatas] = useState();

  const quality = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
  ];
  const countries = [
    { key: "iq", value: "iraq" },
    { key: "cn", value: "china" },
    { key: "ir", value: "iran" },
    { key: "tr", value: "turkey" },
    { key: "ru", value: "russia" },
    { key: "ua", value: "ukraine" },
    { key: "eu", value: "europe" },
    { key: "jo", value: "jordan" },
  ];
  const lines = [
    { key: "101A", value: "101A" },
    { key: "76B", value: "76B" },
    { key: "76C", value: "76C" },
    { key: "51D", value: "51D" },
  ];

  const initialValues = {
    line: "",
    packetQnt: "",
    packetWeight: "",
    quality: "1",
    productCode: "",
    madeIn: "iraq",
    from: "",
    p_comment: "",
    producer: "twana",
    listId: "",
    createdAt: "",
  };

  const validationSchema = Yup.object({
    packetQnt: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    packetWeight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    quality: Yup.string().required("Required"),
    productCode: Yup.string().required("Required"),
    madeIn: Yup.string().required("Required"),
    from: Yup.string().required("Required"),
  });

  const getLists = async () => {
    await HTTP.get(`/lists/all?_search=&_from=`)
      .then((response) => {
        setLists(response.data.rows);
      })
      .catch((error) => {
        setLists([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const handleAdd = (values, onSubmitProps) => {
    Swal.fire({
      title: `${t("on_add_product")}`,
      showCancelButton: true,
      confirmButtonText: `${t("add_product")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      onSubmitProps.setSubmitting(true);
      if (result.isConfirmed) {
        await HTTP.post(`/bpstore/create`, values)
          .then((response) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("added")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              auth.logout();
            } else {
              const message = error.response.data.error.message;
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      }
    });
  };

  useEffect(() => {
    getLists();
  }, []);
  return (
    <div className="bg-sh">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAdd}
        validateOnMount
      >
        {(formik) => (
          <>
            <Row className="mb-3  addUserForm">
              <h4 className="mb-4">{t("add_product")}</h4>
              <Form className="w-100 mb-3">
                <Row className="mb-4">
                  <Col md={6}>
                    <Autocomplete
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      size="small"
                      id="combo-box-demo"
                      name="listId"
                      label={t("select_list")}
                      options={lists}
                      getOptionLabel={(option) =>
                        `${option.name}*${option.type}`
                      }
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          setSelectedListDatas("");
                        } else {
                          setSelectedListDatas(newValue);

                          formik.setFieldValue("listId", newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<em>{t("select_list")}</em>}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.productCode}
                      error={formik.errors.productCode}
                      touched={formik.touched.productCode}
                      type="text"
                      name="productCode"
                      label={t("code")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.madeIn}
                      error={formik.errors.madeIn}
                      touched={formik.touched.madeIn}
                      options={countries}
                      name="madeIn"
                      label={t("made_in")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.packetQnt}
                      error={formik.errors.packetQnt}
                      touched={formik.touched.packetQnt}
                      type="number"
                      name="packetQnt"
                      label={t("packet_qnt")}
                      max={selectedListDatas?.standartPacketQnt}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.quality}
                      error={formik.errors.quality}
                      touched={formik.touched.quality}
                      options={quality}
                      name="quality"
                      label={t("quality")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.packetWeight}
                      error={formik.errors.packetWeight}
                      touched={formik.touched.packetWeight}
                      type="number"
                      name="packetWeight"
                      label={t("packet_weight")}
                      step={0.01}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.line}
                      error={formik.errors.line}
                      touched={formik.touched.line}
                      options={lines}
                      name="line"
                      label={t("line")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.from}
                      error={formik.errors.from}
                      touched={formik.touched.from}
                      type="text"
                      name="from"
                      label={t("from")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikController
                      control="textarea"
                      formik={formik}
                      value={formik.values.p_comment}
                      error={formik.errors.p_comment}
                      touched={formik.touched.p_comment}
                      name="p_comment"
                      label={t("comment")}
                      disabled={!selectedListDatas}
                    />
                  </Col>
                </Row>
                <div className="w-100">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("add_product")}
                  </Button>
                </div>
              </Form>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
};

export default BpAddProduct;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    blackpipe: "بلاك بایب",
    dashboard: "الصفحة الرئيسية",
    users: "المستخدمون",
    login: "تسجيل الدخول",
    logout: "تسجيل خروج",
    roll: "رول",
    rolls: "رولات",
    store: "مخزن",
    lists: "قائمة الإنتاج",
    activity: "نشاط",
    slitter: "شراحة",
    pieces: "مخزن شراحة",
    piece: "شريحة",
    wastes: "تلف شراحة",
    tarqeq: "الترقیق",
    tambur: "تمبور",
    sent_to_tambur: "مرسل الی تمبور",
    list_name: "اسم القائمة",
    produce: "أنتج",
    produce_new: "أنتج",
    f_store: "مخزن مصنع",
    bp_store: "مخزن بلاك بايب",
    qb_store: "مخزن قنديل بلباس",
    transfers: "المنتجات المرسلة",
    slitteds: "الرولات المقطوعة",
    deleted_slitteds: "الشرائح محذوفة",
    deleted_pieces: "الشرائح المحذوفة",
    wastings: "البقايا/تلف",
    removed_from_tambur: "المحذوفات",
    spare_lists: "قائمة المواد الاحطياطية",
    spare_parts: "المواد الاحطياطية",
    deleted_rolls: "الرولات المحذوفة",
    cut_roll: "رولات مقطوعة",
    plets: "بلیت",
    sold_plets: "بلیت مُباع",
    // \\\\\\\\\\\\\\
    kg: "کغم",
    m: "متر",
    mm: "ملم",
    is_cut: "مقطوع",
    number_of_plets: "عدد",
    piece_in_ton: "عدد في الطن",
    all_report: "تقرير للجميع",
    bp_rolls: "رولات من بلاك بايب",
    qb_rolls: "رولات من قندیل بلباس",
    packets: "ربطة",
    whole_roll: "قطع الرول كله",
    how_many_groups: "مقطعة إلى X مجموعات",
    number_of_pieces: "عدد القطع",
    length: "طول",
    piece_weight: "وزن القطعة",
    note: "ملاحضة",
    report: "تقرير",
    pr_weight: "الوزن المتحمل",
    product_report: "التقرير إنتاج",
    product_name: "اسم المنتج",
    s_packet_qnt: "الكمية القياسية في الربطة",
    spq: "الكمية في الربطة",
    s_packet_w: "الوزن القياسية للربطة",
    s_piece_w: "الوزن القياسية للربطة",
    select_list: "حدد القائمة",
    select_line: "اختر الخط",
    type: "نوع",
    qnt: "عدد",
    weight: "وزن",
    code: "کود",
    made_in: "صنع في",
    from: "من",
    date: "التاريخ",
    line: "خط",
    quality: "الجودة",
    car_no: "رقم السياره",
    car_type: "نوع السيارة",
    sell: "بيع",
    sell_to: "يباع الي",
    sold_by: "يباع من قبل",
    sold: "مُباع",
    driverName: "اسم السائق",
    driverPhone: "هاتف السائق",
    pipes: "عدد",
    total_weight: "الوزن الكلي",
    packet_qnt: "عدد الربطة",
    packet_weight: "الوزن الربطة",
    thikness: "سمك",
    width: "عرض",
    net_weight: "الوزن الصافي",
    gross_weight: "الوزن الإجمالي",
    tLength: "الطول النظري",
    aLength: "الطول الفعلي",
    rollQuality: "جودة الرول",
    comment: "تعليق",
    added_by: "تم الاضافة من قبل",
    time: "الوقت",
    remains: "بقايا/تلف",
    devid_into: "تقسم إلى",
    devided_into: "مقسمة إلى",
    real_width: "العرض الواقعي",
    slitted_by: "تم القطع من قبل",
    roll_code: "کود رول",
    tarqeq_length: "الطول بعد الترقيق",
    tarqeq_thikness: "السمك بعد الترقيق",
    deleted_comment: "السبب الحذف",
    deleted_date: "تاريخ الحذف",
    deleted_by: "تم الحذف من قبل",
    upload_image: "تحميل الصورة",
    tambured_by: "مرسل من جهة",
    role: "دور",
    username: "اسم المستخدم",
    name: "اسم",
    phone: "هاتف",
    password: "كلمة المرور",
    reports: "التقارير",
    technician: "فني",
    Report_of: "تقرير عن",
    from_time: "من",
    to_time: "الي",
    speed: "سرعة",
    report_form: "رأس نموذج التقرير",
    report_list: "أنواع التقارير",
    year: "سنة",
    none: "غير ذالك",
    all: "كل",
    print: "طبع",
    used_spares: "المواد الاحطياطية مستعملة",
    usedBy: "استعمل من قبل",
    gaveTo: "أعطى ل",
    reset_password: "إعادة تعيين كلمة المرور",
    newPassword: "كلمة مرور جديدة",
    day_series: "تسلسل اليوم",
    piece_series: "تسلسل شريحة",
    actual_weight: "الوزن الواقعي",
    total_real_weight: "إجمالي الوزن الحقيقي",
    total: "المجموع",
    // \\\\\\\\\\\\\\
    reset: "اعاده تعيين",
    reseted: "تمت إعادة التعيين",
    use: "استخدام",
    used: "مستعمل",
    send: "ارسال",
    sent: "تم ارسال",
    slit: "قطع",
    slited: "تم القطع",
    delete: "حذف",
    deleted: "تم الحذف",
    edit: "تعدیل",
    update: "التحديث",
    updated: "تم التحدث",
    added: "تم الاضافة",
    cancel: "الغاء",
    search: "بحث",
    transfer: "ارسال",
    transferred: "تم ارسال",
    transfer_to: "ارسال إلى",
    transferred_to: "تم ارسال إلى",
    transferred_by: "تم ارسال من قبل",
    return: "يعود",
    returned: "عاد",
    cut: "قطع رول",
    add_roll: "إضافة رول",
    add_list: "إضافة القائمة",
    add_product: "إضافة منتج",
    add_wasting: "إضافة البقايا/تلف",
    add_piece: "إضافة شريحة",
    add_spare_list: "إضافة قائمة المواد الاحطياطية",
    add_spare: "إضافة المواد الاحطياطية",
    add_user: "إضافة مستخدم",
    add_plet: "إضافة بلیت",
    // \\\\\\\\\\\\\
    on_add_product: "هل أنت متأكد من أنك تريد إنتاج؟",
    on_transfer: "هل أنت متأكد من أنك تريد إرسال؟",
    on_produce: "هل أنت متأكد من أنك تريد إنتاج؟",
    on_add: "هل أنت متأكد أنك تريد إضافة؟",
    on_update: "هل أنت متأكد أنك تريد التحديث؟",
    on_return: "هل أنت متأكد أنك تريد العودة؟",
    on_tambur: "هل أنت متأكد من أنك تريد تمبور؟",
    on_slit: "هل أنت متأكد من أنك تريد القطع؟",
    on_tarqeq: "هل أنت متأكد من أنك تريد الترقق؟",
    on_delete: "هل أنت متأكد أنك تريد حذف؟",
    on_delete_per: "هل أنت متأكد أنك تريد الحذف نهائيًا؟",
    on_use: "هل أنت متأكد أنك تريد استخدام؟",
    on_reset: "هل أنت متأكد من أنك تريد إعادة تعيين كلمة المرور؟",
    on_cut: "هل أنت متأكد أنك تريد قص هذه الرول؟",
    on_sell: "هل أنت متأكد أنك تريد البيع؟",
    // \\\\\\\\\\
    productions: "الانتاج",
    qandelblbas: "قندیل بلباس",
    empty_store: "هذا المغزن فارغ",
    not_enough: "لا يكفي الإنتاج في المتجر!",
    all_sold: "جميع البيانات المباعة",
    size: "حجم",
    seller: "البائع",
    Data_Not_Found: "لم يتم العثور على بيانات",
    page_not_found: "لم يتم العثور على الصفحة",
    allproducts: "جميع المنتجات",
    for_order: "للطلب والاستفسارات",
  },
};

import { Button } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BiListPlus } from "react-icons/bi";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { useState } from "react";
import FormikController from "../../Forms/FormikController";
import { HTTP } from "../../../HTTPS";

const AddPlet = ({ getData }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [showForm, setShowForm] = useState(false);
  const types = [
    { key: "hr", value: "HR" },
    { key: "gi", value: "GI" },
    { key: "gr", value: "CR" },
  ];
  const rollQ = [
    { key: "A", value: "A" },
    { key: "B", value: "B" },
  ];
  const countries = [
    { key: "cn", value: "china" },
    { key: "iq", value: "iraq" },
    { key: "ir", value: "iran" },
    { key: "tr", value: "turkey" },
    { key: "ru", value: "russia" },
    { key: "ua", value: "ukraine" },
    { key: "eu", value: "europe" },
    { key: "jo", value: "jordan" },
  ];
  const initialValues = {
    length: "",
    weight: "",
    from: "",
    rollData: {
      code: "",
      type: "HR",
      rollQuality: "A",
      addedBy: localStorage.getItem("username"),
      madeIn: "china",
      width: "",
      thikness: "",
      isAvailable: false,
    },
  };
  const validationSchema = Yup.object({
    length: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
      weight: Yup.number()
      .positive("Must be a positive Number")
      .required("Required"),
    from: Yup.string().required("Required"),
    rollData: Yup.object({
      code: Yup.string().required("Required"),
      width: Yup.string().required("Required"),
      madeIn: Yup.string().required("Required"),
      thikness: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),
  });
  const handleAdd = (values, onSubmitProps) => {
    Swal.fire({
      title: `${t("on_add")}`,
      showCancelButton: true,
      confirmButtonText: `${t("add_plet")}`,
      confirmButtonColor: "red",
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      onSubmitProps.setSubmitting(true);
      if (result.isConfirmed) {
        await HTTP.post(`/plets/create`, values)
          .then((response) => {
            getData();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${t("added")}`,
              showConfirmButton: false,
              timer: 1500,
            });
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            setShowForm(false);
          })
          .catch((error) => {
            const message = error.response.data.error.message;
            if (error.response.status == 401) {
              auth.logout();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "info",
                title: message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            onSubmitProps.setSubmitting(false);
          });
      }
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleAdd}
      validateOnMount
    >
      {(formik) => (
        <>
          <Row className="mb-3">
            <Col className="d-flex justify-content-end px-md-5 px-3">
              <div
                className="adduserbtn"
                onClick={() => {
                  formik.resetForm();
                  setShowForm(!showForm);
                }}
              >
                <span className="mb-3 add_span">{t("add_plet")} </span>
                {showForm ? <AiOutlineMinusCircle /> : <BiListPlus />}
              </div>
            </Col>
          </Row>
          <Collapse in={showForm}>
            <Row className="mb-3 addUserForm">
              <Form className="w-100 mb-3">
                <Row>
                  <Col md={6}>
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.rollData.code}
                      error={formik.errors.rollData?.code}
                      touched={formik.touched.rollData?.code}
                      type="text"
                      name="rollData.code"
                      label={t("code")}
                    />
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.rollData.thikness}
                      error={formik.errors.rollData?.thikness}
                      touched={formik.touched.rollData?.thikness}
                      type="number"
                      name="rollData.thikness"
                      label={t("thikness")}
                      step={0.01}
                    />
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.weight}
                      error={formik.errors.weight}
                      touched={formik.touched.weight}
                      type="number"
                      name="weight"
                      label={t("weight")}
                      step={0.1}
                    />
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.rollData.width}
                      error={formik.errors.rollData?.width}
                      touched={formik.touched.rollData?.width}
                      type="number"
                      name="rollData.width"
                      label={t("width")}
                    />
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.length}
                      error={formik.errors.length}
                      touched={formik.touched.length}
                      type="number"
                      name="length"
                      label={t("length")}
                      step={0.01}
                    />
                    <FormikController
                      control="input"
                      formik={formik}
                      value={formik.values.from}
                      error={formik.errors.from}
                      touched={formik.touched.from}
                      type="text"
                      name="from"
                      label={t("from")}
                    />
                  </Col>
                  <Col md={6}>
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.rollData.type}
                      error={formik.errors.rollData?.type}
                      touched={formik.touched.rollData?.type}
                      options={types}
                      name="rollData.type"
                      label={t("type")}
                    />
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.rollData.rollQuality}
                      error={formik.errors.rollData?.rollQuality}
                      touched={formik.touched.rollData?.rollQuality}
                      options={rollQ}
                      name="rollData.rollQuality"
                      label={t("rollQuality")}
                    />
                    <FormikController
                      control="select"
                      formik={formik}
                      value={formik.values.rollData.madeIn}
                      error={formik.errors.rollData?.madeIn}
                      touched={formik.touched.rollData?.madeIn}
                      options={countries}
                      name="rollData.madeIn"
                      label={t("made_in")}
                    />
                  </Col>
                </Row>
                <div className="w-100 d-flex justify-content-end">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {t("add_plet")}
                  </Button>
                </div>
              </Form>
              <hr />
            </Row>
          </Collapse>
        </>
      )}
    </Formik>
  );
};

export default AddPlet;

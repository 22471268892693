import React, { useState } from "react";
import { Button, Table, DatePicker, Space, Spin, Input, Badge } from "antd";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { HTTP } from "../../../HTTPS";
import { useEffect } from "react";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth";
import { IoIosCut } from "react-icons/io";
import CutModal from "./CutModal";
import EachPlet from "./EachPlet";

const { Search } = Input;

const CutRolls = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = localStorage.getItem("role");
  const [rollData, setRollData] = useState([]);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rollsLength, setRollsLength] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");

  const [curRollData, setCutRollData] = useState([]);
  const [climit, setCLimit] = useState(25);
  const [ccount, setCCount] = useState(0);
  const [ccurrentPage, setCCurrentPage] = useState(1);
  const [crollsLength, setCRollLength] = useState(0);
  const [csearch, setCSearch] = useState("");
  const [cloading, setCLoading] = useState(false);
  const [cdate, setCDate] = useState("");

  const [openCutModal, setOpenCutModal] = useState(false);
  const [cutRecord, setCutRecord] = useState({});

  const handleCutModalClose = () => {
    setCutRecord([]);
    setOpenCutModal(false);
  };

  const RollColumns = [
    {
      title: "#",
      width: 15,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("is_cut")}`,
      key: "id",
      width: 50,
      render: (record) => (
        <span>
          {record.isCut ? (
            <Badge className="mx-1 badge-today" color="red" />
          ) : (
            <Badge className="mx-1 badge-today" color="green" />
          )}
        </span>
      ),
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("width")}`,
      dataIndex: "width",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      dataIndex: "type",
      key: "id",
      width: 50,
    },
    {
      title: `${t("rollQuality")}`,
      dataIndex: "rollQuality",
      key: "id",
      width: 50,
    },
    {
      title: `${t("net_weight")}`,
      dataIndex: "netWeight",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("made_in")}`,
      dataIndex: "madeIn",
      key: "id",
      width: 75,
      render: (record) => <span>{record}</span>,
    },
    {
      title: `${t("date")}`,
      dataIndex: "createdAt",
      key: "id",
      width: 75,
      render: (record) => <span>{moment(record).format("Y.MM.DD HH:mm")}</span>,
    },
    ...(role == "Admin" || role == "Accountant" || role == "QandelBlbas"
      ? [
          {
            dataIndex: "",
            width: 50,
            fixed: "right",
            key: "x",
            render: (record) => (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    setCutRecord(record);
                    setOpenCutModal(true);
                  }}
                  type="primary"
                  ghost
                >
                  <IoIosCut />{" "}
                  <span className="d-none d-md-inline"> {t("cut")}</span>
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];
  const CutRollColumns = [
    {
      title: "#",
      width: 15,
      key: "index",
      render: (text, record, index) => {
        return currentPage === 1
          ? index + 1
          : (currentPage - 1) * limit + (index + 1);
      },
    },
    {
      title: `${t("thikness")}`,
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record.thikness} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("width")}`,
      dataIndex: "width",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("mm")}
        </span>
      ),
    },
    {
      title: `${t("type")}`,
      dataIndex: "type",
      key: "id",
      width: 50,
    },
    {
      title: `${t("rollQuality")}`,
      dataIndex: "rollQuality",
      key: "id",
      width: 50,
    },
    {
      title: `${t("net_weight")}`,
      dataIndex: "netWeight",
      key: "id",
      width: 75,
      render: (record) => (
        <span>
          {record} {t("kg")}
        </span>
      ),
    },
    {
      title: `${t("made_in")}`,
      dataIndex: "madeIn",
      key: "id",
      width: 75,
      render: (record) => <span>{record}</span>,
    },
    {
      title: `${t("date")}`,
      dataIndex: "createdAt",
      key: "id",
      width: 75,
      render: (record) => <span>{moment(record).format("Y.MM.DD HH:mm")}</span>,
    },
  ];
  const getData = async () => {
    setLoading(true);
    await HTTP.get(
      `/qrolls/all?_isAvailable=1&_limit=${limit}&_page=${currentPage}&_search=${search}&_date=${date}`
    )
      .then((response) => {
        setLoading(false);
        setCount(Math.ceil(response.data.count / limit));
        setRollsLength(response.data.count);
        setRollData(response.data.rows);
      })
      .catch((error) => {
        setLoading(false);
        setRollData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  const getCutData = async () => {
    setCLoading(true);
    await HTTP.get(
      `/qrolls/all?_isAvailable=0&_limit=${climit}&_page=${ccurrentPage}&_search=${csearch}&_date=${cdate}`
    )
      .then((response) => {
        setCLoading(false);
        setCCount(Math.ceil(response.data.count / limit));
        setCRollLength(response.data.count);
        setCutRollData(response.data.rows);
      })
      .catch((error) => {
        setCLoading(false);
        setCutRollData([]);
        if (error.response.status == 401) {
          auth.logout();
        } else if (error.response.status === 404) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${t("Data_Not_Found")}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else alert("Somthing is wrong, Please try again later");
      });
  };
  useEffect(() => {
    getData();
    getCutData();
  }, [limit, currentPage, search, date, climit, ccurrentPage, csearch, cdate]);

  return (
    <>
      {/*ROLLS TABLE */}
      <Row>
        <Col md={6}>
          <h4 className="mb-3">
            {t("rolls")} ({rollsLength})
          </h4>
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={RollColumns}
        size="small"
        className="tbs"
        id="rollsTable"
        tableLayout="fixed"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: loading,
        }}
        pagination={{
          onChange: (current, size) => {
            setLimit(size);
            setCurrentPage(current);
          },
          total: Math.floor(count * limit),
          defaultPageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="px-md-4">
              {record.code && (
                <>
                  <span className=" lh-base">{t("code")}: </span>
                  <span className="fw-bold">{record.code}</span>
                  <br />
                </>
              )}
              <span className=" lh-base">{t("added_by")}: </span>
              <span className="fw-bold">{record.addedBy}</span>
              <br />
              <span className=" lh-base">{t("gross_weight")}: </span>
              <span className="fw-bold">
                {record.grossWeight} {t("kg")}
              </span>
              <br />
              <span className=" lh-base">{t("length")}: </span>
              <span className="fw-bold">
                {record.length} {t("m")}
              </span>
              <br />
              {record.addedComment && (
                <>
                  <span className=" lh-base">{t("comment")}: </span>
                  <span className="fw-bold">{record.addedComment}</span>
                  <br />
                </>
              )}
            </div>
          ),
          columnWidth: 12,
        }}
        dataSource={rollData}
      />
      {/* CUT ROLL TABLE */}
      <Row>
        <Col md={6}>
          <h4 className="mb-3">
            {t("cut_roll")} ({crollsLength})
          </h4>
        </Col>
        <Col md={2}>
          <DatePicker
            placeholder={t("date")}
            className="my-1 my-md-2"
            onChange={(date, dateString) => {
              setCDate(dateString);
            }}
          />
        </Col>
        <Col md={4}>
          <Search
            placeholder={t("search")}
            allowClear
            enterButton
            className="my-1 my-md-2"
            onChange={(e) => {
              setCSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Table
        columns={CutRollColumns}
        size="small"
        className="tbs"
        id="rollsTable"
        tableLayout="fixed"
        rowKey="id"
        loading={{
          indicator: (
            <Space size="middle">
              <Spin size="large" />
            </Space>
          ),
          spinning: cloading,
        }}
        pagination={{
          onChange: (current, size) => {
            setCLimit(size);
            setCCurrentPage(current);
          },
          total: Math.floor(ccount * climit),
          defaultPageSize: climit,
          showSizeChanger: true,
          pageSizeOptions: ["10", "25", "50", "100"],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Row className="m-0 p-0">
              <Col md={4}>
                <div className="px-md-4">
                  {record.code && (
                    <>
                      <span className=" lh-base">{t("code")}: </span>
                      <span className="fw-bold">{record.code}</span>
                      <br />
                    </>
                  )}
                  <span className=" lh-base">{t("added_by")}: </span>
                  <span className="fw-bold">{record.addedBy}</span>
                  <br />
                  <span className=" lh-base">{t("gross_weight")}: </span>
                  <span className="fw-bold">
                    {record.grossWeight} {t("kg")}
                  </span>
                  <br />
                  <span className=" lh-base">{t("length")}: </span>
                  <span className="fw-bold">
                    {record.length} {t("m")}
                  </span>
                  <br />
                  {record.addedComment && (
                    <>
                      <span className=" lh-base">{t("comment")}: </span>
                      <span className="fw-bold">{record.addedComment}</span>
                      <br />
                    </>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <EachPlet record={record} />
              </Col>
            </Row>
          ),
          columnWidth: 12,
        }}
        dataSource={curRollData}
      />
      {/* CUT MODAL */}

      <Modal
        show={openCutModal}
        onHide={handleCutModalClose}
        fullscreen
        aria-labelledby="update-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="update-modal-title"> {t("cut_roll")} </Modal.Title>
        </Modal.Header>
        <Container
          className="pb-5 pb-md-0"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <CutModal
            rollData={cutRecord}
            handleClose={handleCutModalClose}
            getData={getData}
            getCutData={getCutData}
          />
        </Container>
      </Modal>
    </>
  );
};

export default CutRolls;
